import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSONString: any;
};

export type Awss3PostField = {
  __typename: 'AWSS3PostField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type AbsoluteDateRangeFilter = {
  __typename: 'AbsoluteDateRangeFilter';
  gte: Scalars['Date'];
  lt?: Maybe<Scalars['Date']>;
};

export type AbsoluteDateTimeRangeFilter = DateRangeFilterBase & {
  __typename: 'AbsoluteDateTimeRangeFilter';
  gte: Scalars['DateTime'];
  lt?: Maybe<Scalars['DateTime']>;
  rangeType: DateRangeFilterType;
};

export type AccountFilterInput = {
  search?: InputMaybe<KeywordFilterInput>;
};

export type AccountPlanType = Node & {
  __typename: 'AccountPlanType';
  amount: Scalars['Float'];
  billingCycleAnchor?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  currentPeriodEnd: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  /** Stripe id for the customer that owns this subscription. */
  customerId: Scalars['String'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  interval?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  planType?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status: Scalars['String'];
  /** @deprecated use stripeProducts */
  stripeProduct?: Maybe<StripeProduct>;
  stripeProducts?: Maybe<Array<StripeProduct>>;
  subscriptionId?: Maybe<Scalars['String']>;
  trialEnd?: Maybe<Scalars['DateTime']>;
  trialStart?: Maybe<Scalars['DateTime']>;
};

export enum AccountProductName {
  Base = 'BASE',
  Campaign = 'CAMPAIGN',
  Customer = 'CUSTOMER'
}

export type AccountSettingsType = Node & {
  __typename: 'AccountSettingsType';
  emvCpmUsd: Scalars['Float'];
  id: Scalars['ID'];
};

export enum AccountSort {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type AccountType = {
  __typename: 'AccountType';
  automations: Array<Automation>;
  challenges?: Maybe<ChallengeTypePaged>;
  contentFilters?: Maybe<Array<ContentFilterType>>;
  createdAt: Scalars['DateTime'];
  customFields: Array<CustomFieldType>;
  customerFilters?: Maybe<Array<CustomerFilterType>>;
  /** @deprecated use integrations instead */
  fulfillmentAccounts?: Maybe<Array<FulfillAccountType>>;
  id: Scalars['Float'];
  industry?: Maybe<Scalars['String']>;
  integrations?: Maybe<Array<Integration>>;
  labels?: Maybe<PagedLabelType>;
  name: Scalars['String'];
  organization: Organization;
  presignedUrlForRewardCodeUpload?: Maybe<PresignedUrlAndFields>;
  presignedUrlForStoreLogoUpload?: Maybe<PresignedUrlAndFields>;
  programs?: Maybe<PagedProgramType>;
  rewards?: Maybe<PagedRewardType>;
  segments?: Maybe<PagedSegmentType>;
  settings: AccountSettingsType;
  socialAccounts?: Maybe<Array<SocialAccount>>;
  users?: Maybe<Array<UserType>>;
};


export type AccountTypeChallengesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type AccountTypeIntegrationsArgs = {
  where?: InputMaybe<IntegrationFilterInput>;
};


export type AccountTypeLabelsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<LabelSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<LabelFilterInput>;
};


export type AccountTypePresignedUrlForStoreLogoUploadArgs = {
  mimetype: Scalars['String'];
};


export type AccountTypeProgramsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProgramSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<ProgramFilterInput>;
};


export type AccountTypeRewardsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<RewardSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<RewardFilterInput>;
};


export type AccountTypeSegmentsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SegmentSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<SegmentFilterInput>;
};

export type ActivateProgramEcommDiscountCode = {
  __typename: 'ActivateProgramEcommDiscountCode';
  ecommDiscountCode?: Maybe<EcommDiscountCodeType>;
  ok: Scalars['Boolean'];
};

export type ActorCustomerEvent = {
  actor?: Maybe<UserType>;
};

export type AddCustomerNote = {
  __typename: 'AddCustomerNote';
  note?: Maybe<CustomerNoteType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddCustomerNoteEvent = ActorCustomerEvent & BaseCustomerEvent & CustomerNoteEvent & {
  __typename: 'AddCustomerNoteEvent';
  actor?: Maybe<UserType>;
  customerNote: CustomerNoteType;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type AddCustomerSegment = {
  __typename: 'AddCustomerSegment';
  customers?: Maybe<Array<CustomerType>>;
  ok?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<SegmentType>;
};

export type AddLabelCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & LabelCustomerEvent & {
  __typename: 'AddLabelCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  label: LabelType;
  mention: Mention;
};

export type AddMentionLabel = {
  __typename: 'AddMentionLabel';
  label?: Maybe<LabelType>;
  mentions?: Maybe<Array<Mention>>;
  ok?: Maybe<Scalars['Boolean']>;
};

/** Add a customer to a campaign */
export type AddParticipantCustomer = {
  __typename: 'AddParticipantCustomer';
  ok?: Maybe<Scalars['Boolean']>;
  participant?: Maybe<ProgramParticipantType>;
};

export type AddParticipantSegment = {
  __typename: 'AddParticipantSegment';
  ok?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<ProgramParticipantType>>;
  segment?: Maybe<SegmentType>;
};

export type AddSegmentCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & SegmentCustomerEvent & {
  __typename: 'AddSegmentCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  segment: SegmentType;
};

export type AddStoryMention = {
  __typename: 'AddStoryMention';
  igMention?: Maybe<IgMention>;
  ok: Scalars['Boolean'];
};

export type AddTikTokSocialAccount = {
  __typename: 'AddTikTokSocialAccount';
  ok: Scalars['Boolean'];
  ttSocialAccount?: Maybe<TtSocialAccount>;
};

export type AdditionalCriteriaInputType = {
  criteriaDescription?: InputMaybe<Scalars['String']>;
  hasAdditionalCriteria?: InputMaybe<Scalars['Boolean']>;
  shouldMeetProgramRules?: InputMaybe<Scalars['Boolean']>;
  storyCriteriaDescription?: InputMaybe<Scalars['String']>;
};

export type AdditionalCriteriaType = {
  __typename: 'AdditionalCriteriaType';
  criteriaDescription?: Maybe<Scalars['String']>;
  hasAdditionalCriteria?: Maybe<Scalars['Boolean']>;
  shouldMeetProgramRules?: Maybe<Scalars['Boolean']>;
  storyCriteriaDescription?: Maybe<Scalars['String']>;
};

export type AmbassadorCollection = Node & {
  __typename: 'AmbassadorCollection';
  description?: Maybe<Scalars['String']>;
  featuredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  products?: Maybe<Array<Product>>;
  productsCount?: Maybe<Scalars['Int']>;
};


export type AmbassadorCollectionProductsArgs = {
  sharedProductId?: InputMaybe<Scalars['ID']>;
};

export type AmbassadorCollectionProductEvent = Node & {
  __typename: 'AmbassadorCollectionProductEvent';
  action: Scalars['String'];
  id: Scalars['ID'];
  product: Product;
};

export type AmbassadorFeedFilterInput = {
  includeTypes: Array<StorefrontFeedItemTypeEnum>;
  mediaFilters?: InputMaybe<AmbassadorFeedMediaFilterInput>;
};

export type AmbassadorFeedItemType = AmbassadorFeedProductType | AmbassadorMediaType;

export type AmbassadorFeedMediaFilterInput = {
  includeHidden?: InputMaybe<Scalars['Boolean']>;
  includeTagged?: InputMaybe<Scalars['Boolean']>;
  includeUntagged?: InputMaybe<Scalars['Boolean']>;
  mediaTypes?: InputMaybe<Array<MediaTypes>>;
  minPostedAt?: InputMaybe<DateTimeRangeFilterInput>;
};

export type AmbassadorFeedProductType = {
  __typename: 'AmbassadorFeedProductType';
  featuredAt?: Maybe<Scalars['DateTime']>;
  isOnlyProductInCollection?: Maybe<Scalars['Boolean']>;
  pickedAt?: Maybe<Scalars['DateTime']>;
  product: Product;
};

export type AmbassadorFeedSortInput = {
  mediaSort?: InputMaybe<Array<AmbassadorMediaSort>>;
};

export type AmbassadorHub = Node & {
  __typename: 'AmbassadorHub';
  ambassadorHubLink?: Maybe<Scalars['String']>;
  ambassadorStats?: Maybe<AmbassadorStats>;
  availableChallengeRewards?: Maybe<Array<AvailableReward>>;
  availableProgramTierRewards?: Maybe<Array<AvailableReward>>;
  earnedChallengeRewards?: Maybe<Array<EarnedReward>>;
  earnedDmRewards?: Maybe<Array<EarnedReward>>;
  earnedNonProgramRewards?: Maybe<Array<EarnedReward>>;
  earnedProgramTierRewards?: Maybe<Array<EarnedReward>>;
  firstName?: Maybe<Scalars['String']>;
  hiddenIgReelsCount?: Maybe<Scalars['Int']>;
  hiddenIgStoriesCount?: Maybe<Scalars['Int']>;
  hiddenTtFeedCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  igUser?: Maybe<IgUser>;
  invisibleUgcPosts?: Maybe<Array<InvisibleMedia>>;
  participants?: Maybe<Array<AmbassadorHubProgramParticipant>>;
  shoppableParticipant?: Maybe<AmbassadorHubProgramParticipant>;
  shoppableParticipants?: Maybe<Array<AmbassadorHubProgramParticipant>>;
  /** @deprecated use participant */
  signupDate?: Maybe<Scalars['String']>;
  socialStats?: Maybe<AmbassadorHubSocialStats>;
  storefrontParticipant?: Maybe<AmbassadorHubProgramParticipant>;
  ttUser?: Maybe<TtUser>;
  visibleUgcPosts?: Maybe<Array<VisibleMedia>>;
};


export type AmbassadorHubAmbassadorStatsArgs = {
  where?: InputMaybe<AmbassadorStatsInput>;
};

/** participant fields for ambassador hub users */
export type AmbassadorHubProgramParticipant = Node & ProgramParticipant & {
  __typename: 'AmbassadorHubProgramParticipant';
  affiliateDiscountCodes: Array<EcommDiscountRedeemCodeType>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  canEarnCommissions: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  customerFirstName?: Maybe<Scalars['String']>;
  editShoppableAmbassadorLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  program: PublicProgram;
  shoppableAmbassadorLink?: Maybe<Scalars['String']>;
};

export type AmbassadorHubSocialStats = {
  __typename: 'AmbassadorHubSocialStats';
  approvedAllTimeActivity: Scalars['Int'];
  approvedThisMonthActivity: Scalars['Int'];
  rejectedAllTimeActivity: Scalars['Int'];
  rejectedThisMonthActivity: Scalars['Int'];
};

export enum AmbassadorMediaSort {
  FeaturedAt = 'FEATURED_AT',
  PostedAt = 'POSTED_AT',
  Untagged = 'UNTAGGED',
  Visible = 'VISIBLE'
}

export type AmbassadorMediaType = {
  __typename: 'AmbassadorMediaType';
  hasNextMedia?: Maybe<Scalars['Boolean']>;
  hasPrevMedia?: Maybe<Scalars['Boolean']>;
  hidden: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  isShoppable: Scalars['Boolean'];
  media: VisibleMedia;
  nextCursor?: Maybe<Scalars['String']>;
  pagingId?: Maybe<Scalars['String']>;
  prevCursor?: Maybe<Scalars['String']>;
};

export type AmbassadorProfileInput = {
  bio?: InputMaybe<Scalars['String']>;
  imageData?: InputMaybe<Scalars['String']>;
  imageSource?: InputMaybe<EcommMediaSource>;
};

export type AmbassadorStats = {
  __typename: 'AmbassadorStats';
  commissions: Scalars['Float'];
  commissionsAwaitingApproval: Scalars['Float'];
  commissionsOwed: Scalars['Float'];
  commissionsPaid: Scalars['Float'];
  commissionsPending: Scalars['Float'];
  commissionsTotal: Scalars['Float'];
  landedOrdersApproved: Scalars['Int'];
  landedOrdersAwaitingApproval: Scalars['Int'];
  landings: Scalars['Int'];
  ordersApproved: Scalars['Int'];
  ordersAwaitingApproval: Scalars['Int'];
  ordersTotal: Scalars['Int'];
  revenue: Scalars['Float'];
  revenueApproved: Scalars['Float'];
  revenueAwaitingApproval: Scalars['Float'];
  revenueTotal: Scalars['Float'];
  sales: Scalars['Float'];
  totalCustomers: Scalars['Int'];
  totalLandedOrders: Scalars['Int'];
  totalOrders: Scalars['Int'];
};

export type AmbassadorStatsInput = {
  currencyCode?: InputMaybe<CurrencyCodeEnum>;
  disjointRefunds?: InputMaybe<Scalars['Boolean']>;
  eventTypes?: InputMaybe<Array<EcommWebEventType>>;
  orderMaturationDate?: InputMaybe<Scalars['DateTime']>;
  ordersAwaitingApproval?: InputMaybe<Scalars['Boolean']>;
  paymentStatus?: InputMaybe<PaymentStatusEnum>;
  paymentStatusesFilter?: InputMaybe<Array<PaymentStatusEnum>>;
  programIds?: InputMaybe<IdFilterInput>;
  rangeAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type AmbassadorStatsTimeseries = {
  __typename: 'AmbassadorStatsTimeseries';
  commissions: Scalars['Float'];
  endDate: Scalars['Date'];
  landings: Scalars['Int'];
  revenue: Scalars['Float'];
  sales: Scalars['Float'];
  startDate: Scalars['Date'];
  totalCustomers: Scalars['Int'];
  totalLandedOrders: Scalars['Int'];
  totalOrders: Scalars['Int'];
};

export type AmbassadorStatsTimeseriesInput = {
  currencyCode?: InputMaybe<CurrencyCodeEnum>;
  disjointRefunds?: InputMaybe<Scalars['Boolean']>;
  eventTypes?: InputMaybe<Array<EcommWebEventType>>;
  ordersAwaitingApproval?: InputMaybe<Scalars['Boolean']>;
  paymentStatus?: InputMaybe<PaymentStatusEnum>;
  programIds?: InputMaybe<IdFilterInput>;
  rangeAt?: InputMaybe<DateTimeFilterInput>;
};

export type AmbassadorUser = Node & {
  __typename: 'AmbassadorUser';
  email: Scalars['String'];
  id: Scalars['ID'];
  relationships?: Maybe<PagedStoreAmbassadorRelationship>;
};

export type AnySegmentCampaignFilterInput = {
  campaigns?: InputMaybe<Array<Scalars['ID']>>;
  segments?: InputMaybe<Array<Scalars['ID']>>;
};

export type AttributedSaleCustomerEvent = BaseCustomerEvent & {
  __typename: 'AttributedSaleCustomerEvent';
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  platformOrderId: Scalars['String'];
};

export enum AttributionLandedEnum {
  First = 'FIRST',
  Last = 'LAST'
}

/** Authenticate a fb business user using the code returned from facebook login */
export type AuthFbBusiness = {
  __typename: 'AuthFBBusiness';
  fbUser?: Maybe<FbUser>;
  ok: Scalars['Boolean'];
};

export type Automation = Node & {
  __typename: 'Automation';
  account: AccountType;
  action: AutomationAction;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  trigger: TriggerTypes;
};

export type AutomationAction = Node & {
  __typename: 'AutomationAction';
  id: Scalars['ID'];
  integration: IntegrationTypes;
};

export type AvailableReward = {
  __typename: 'AvailableReward';
  description?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BaseCustomerEvent = {
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type BaseFilterType = {
  entity: FilterEntity;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
};

export type BiographyFilterInput = {
  all?: InputMaybe<Array<Array<Scalars['String']>>>;
  any?: InputMaybe<Array<Scalars['String']>>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  none?: InputMaybe<Array<Scalars['String']>>;
};

export type BluecoreIntegration = Integration & Node & {
  __typename: 'BluecoreIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
};

export type BrazeIntegration = Integration & Node & {
  __typename: 'BrazeIntegration';
  apiUrl: Scalars['String'];
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type CampaignMentionStats = MentionStats & {
  __typename: 'CampaignMentionStats';
  emv: Scalars['Float'];
  engagement: Scalars['Float'];
  impressions: Scalars['Float'];
  posts: Scalars['Int'];
  ugcValue: Scalars['Float'];
};

export type CampaignSettingsInput = {
  ambassadorBaseUrl?: InputMaybe<Scalars['String']>;
  ambassadorUgcApprovedOnly?: InputMaybe<Scalars['Boolean']>;
  attributionType?: InputMaybe<AttributionLandedEnum>;
  attributionWindow?: InputMaybe<Scalars['Int']>;
  autoApproved?: InputMaybe<Scalars['Boolean']>;
  commissionsEnabled?: InputMaybe<Scalars['Boolean']>;
  ecommCustomFieldIds?: InputMaybe<Array<Scalars['ID']>>;
  hideSalesPrice?: InputMaybe<Scalars['Boolean']>;
  payoutSettings?: InputMaybe<PayoutSettingsInput>;
  postingRulesUrl?: InputMaybe<Scalars['String']>;
  productPickerCollectionIds?: InputMaybe<Array<Scalars['ID']>>;
  requiresVerification?: InputMaybe<Scalars['Boolean']>;
  showOutOfStockProducts?: InputMaybe<Scalars['Boolean']>;
  signupFormUrl?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  storeIntegrationId?: InputMaybe<Scalars['ID']>;
};

export type CampaignType = {
  __typename: 'CampaignType';
  ambassadorStats?: Maybe<AmbassadorStats>;
  ambassadorStatsTimeseries?: Maybe<Array<AmbassadorStatsTimeseries>>;
  endAt?: Maybe<Scalars['DateTime']>;
  events?: Maybe<PagedCampaignEventType>;
  id: Scalars['Float'];
  isActive: Scalars['Boolean'];
  ltvBreakdown: LtvBreakdown;
  mentionStats?: Maybe<CampaignMentionStats>;
  mentionStatsTimeseries?: Maybe<Array<SocialDataPoint>>;
  mentions?: Maybe<PagedMentionType>;
  nextPaymentDate?: Maybe<Scalars['Date']>;
  orderStats?: Maybe<OrderStats>;
  orderStatsTimeseries?: Maybe<Array<OrderStatsTimeseries>>;
  participantStats?: Maybe<ParticipantStats>;
  participantStatsTimeseries?: Maybe<Array<ParticipantStatsTimeseries>>;
  participants?: Maybe<PagedParticipants>;
  paymentPeriods?: Maybe<Array<PaymentPeriod>>;
  program?: Maybe<ProgramType>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  socialAccount?: Maybe<SocialAccount>;
  startAt: Scalars['DateTime'];
};


export type CampaignTypeAmbassadorStatsArgs = {
  where?: InputMaybe<AmbassadorStatsInput>;
};


export type CampaignTypeAmbassadorStatsTimeseriesArgs = {
  timeDimension?: InputMaybe<TimeDimension>;
  timezone: Scalars['String'];
  where?: InputMaybe<AmbassadorStatsTimeseriesInput>;
};


export type CampaignTypeEventsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventsInput>;
};


export type CampaignTypeLtvBreakdownArgs = {
  where?: InputMaybe<OrdersInput>;
};


export type CampaignTypeMentionStatsArgs = {
  where?: InputMaybe<CustomerMentionStatsFilters>;
};


export type CampaignTypeMentionStatsTimeseriesArgs = {
  timeDimension?: InputMaybe<TimeDimension>;
  timezone: Scalars['String'];
  where: MentionFilterInput;
};


export type CampaignTypeMentionsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MentionSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<MentionFilterInput>;
};


export type CampaignTypeOrderStatsArgs = {
  where?: InputMaybe<OrdersInput>;
};


export type CampaignTypeOrderStatsTimeseriesArgs = {
  timeDimension?: InputMaybe<TimeDimension>;
  timezone: Scalars['String'];
  where?: InputMaybe<OrdersInput>;
};


export type CampaignTypeParticipantStatsArgs = {
  ambassadorStatsWhere?: InputMaybe<AmbassadorStatsInput>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
  where?: InputMaybe<ParticipantsInput>;
};


export type CampaignTypeParticipantStatsTimeseriesArgs = {
  timeDimension?: InputMaybe<TimeDimension>;
  timezone: Scalars['String'];
  where?: InputMaybe<ParticipantsInput>;
};


export type CampaignTypeParticipantsArgs = {
  ambassadorStatsWhere?: InputMaybe<AmbassadorStatsInput>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
  sortBy?: InputMaybe<ParticipantSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<ParticipantsInput>;
};

export type CampaignsFilterInput = {
  any?: InputMaybe<Array<Scalars['ID']>>;
  none?: InputMaybe<Array<Scalars['ID']>>;
};

export type ChallengeApprovalStatusCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & {
  __typename: 'ChallengeApprovalStatusCustomerEvent';
  actor?: Maybe<UserType>;
  challenge: ChallengeType;
  challengeMediaApprovalStatus: MentionStatus;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mention: Mention;
};

export type ChallengeAutomatedRewardInstructions = {
  instructions: Scalars['String'];
  rewardId: Scalars['ID'];
};

export enum ChallengeBriefDeliveryMethodEnum {
  Email = 'EMAIL',
  Sms = 'SMS',
  SocialPost = 'SOCIAL_POST',
  SomethingElse = 'SOMETHING_ELSE',
  Website = 'WEBSITE'
}

export type ChallengeMediaStats = {
  __typename: 'ChallengeMediaStats';
  emv: Scalars['Float'];
  participants: Scalars['Int'];
  total: Scalars['Int'];
};

export type ChallengeMediaType = Node & {
  __typename: 'ChallengeMediaType';
  approval: MentionStatus;
  challenge: ChallengeType;
  id: Scalars['ID'];
  media: Media;
};

export enum ChallengeMessageDeliveryMethodEnum {
  Email = 'EMAIL',
  Ig = 'IG',
  Sms = 'SMS'
}

export enum ChallengeMessageTriggerEnum {
  End = 'END',
  ParticipantProgress = 'PARTICIPANT_PROGRESS',
  Reminders = 'REMINDERS',
  Start = 'START'
}

export type ChallengePaintedDoorType = {
  __typename: 'ChallengePaintedDoorType';
  automatedMessageContent?: Maybe<Scalars['String']>;
  automatedMessageDeliveryMethods?: Maybe<Array<ChallengeMessageDeliveryMethodEnum>>;
  automatedMessageTriggers?: Maybe<Array<ChallengeMessageTriggerEnum>>;
  briefContent?: Maybe<Scalars['String']>;
  briefDeliveryMethods?: Maybe<Array<ChallengeBriefDeliveryMethodEnum>>;
  winningInstructions?: Maybe<Array<ChallengeRewardInstructionType>>;
};

export enum ChallengeRewardCriteriaEnum {
  HandPicked = 'HAND_PICKED',
  Participating = 'PARTICIPATING',
  SpecificCriteria = 'SPECIFIC_CRITERIA'
}

export type ChallengeRewardInstructionType = {
  __typename: 'ChallengeRewardInstructionType';
  instructions: Scalars['String'];
  reward?: Maybe<RewardType>;
};

export type ChallengeRewardType = Node & {
  __typename: 'ChallengeRewardType';
  id: Scalars['ID'];
  reward?: Maybe<RewardType>;
  winningCriteria: ChallengeRewardCriteriaEnum;
};

export enum ChallengeStatusEnum {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Live = 'LIVE',
  Paused = 'PAUSED',
  Upcoming = 'UPCOMING'
}

export type ChallengeType = Node & {
  __typename: 'ChallengeType';
  additionalCriteria?: Maybe<AdditionalCriteriaType>;
  challengeRewards?: Maybe<Array<ChallengeRewardType>>;
  currentStatus: ChallengeStatusEnum;
  endedAt?: Maybe<Scalars['DateTime']>;
  hashtags?: Maybe<Array<Scalars['String']>>;
  hashtagsCriteriaOperator: CriteriaOperatorEnum;
  id: Scalars['ID'];
  isRecentlyCompleted?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  keywordsCriteriaOperator: CriteriaOperatorEnum;
  mediaStats?: Maybe<ChallengeMediaStats>;
  name: Scalars['String'];
  paintedDoor?: Maybe<ChallengePaintedDoorType>;
  postTypes?: Maybe<Array<IgMediaPostType>>;
  programs?: Maybe<Array<ProgramType>>;
  reviewLock?: Maybe<ReviewLockType>;
  reviewPendingPosts?: Maybe<PagedMentionType>;
  socialAccounts: Array<SocialAccount>;
  startedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ChallengeTypeReviewPendingPostsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MentionSort>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ChallengeTypePaged = {
  __typename: 'ChallengeTypePaged';
  cursor?: Maybe<Scalars['String']>;
  results: Array<ChallengeType>;
  total: Scalars['Int'];
};

export type ChallengesFilterInput = {
  all?: InputMaybe<Array<Array<Scalars['ID']>>>;
  any?: InputMaybe<Array<Scalars['ID']>>;
  none?: InputMaybe<Array<Scalars['ID']>>;
};

export type ClaimChallengeReviewLock = {
  __typename: 'ClaimChallengeReviewLock';
  ok?: Maybe<Scalars['Boolean']>;
  reviewLock?: Maybe<ReviewLockType>;
};

export type ClaimProgramReviewLock = {
  __typename: 'ClaimProgramReviewLock';
  ok?: Maybe<Scalars['Boolean']>;
  reviewLock?: Maybe<ReviewLockType>;
};

export enum ClientSftpObjectType {
  OrderLineItems = 'ORDER_LINE_ITEMS',
  Prices = 'PRICES',
  Products = 'PRODUCTS',
  Refunds = 'REFUNDS',
  RefundLineItems = 'REFUND_LINE_ITEMS'
}

export type CodeUploadInput = {
  codeFile: Scalars['String'];
  reenableDms?: InputMaybe<Scalars['Boolean']>;
};

export enum CodesLevel {
  Empty = 'EMPTY',
  Full = 'FULL',
  High = 'HIGH',
  Low = 'LOW'
}

export type CollectionType = Node & {
  __typename: 'CollectionType';
  id: Scalars['ID'];
  imageSrc?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Product>>;
  title: Scalars['String'];
};


export type CollectionTypeProductsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type ColumnMapping = {
  __typename: 'ColumnMapping';
  fileColumn: Scalars['String'];
  internalKey: ProductInternalKeyEnum;
};

export type CommerceCloudIntegration = EcommStore & Integration & Node & {
  __typename: 'CommerceCloudIntegration';
  brandMessage?: Maybe<Scalars['String']>;
  canSyncCollections: Scalars['Boolean'];
  canSyncProducts: Scalars['Boolean'];
  capabilities: Array<IntegrationCapabilityEnum>;
  clientId?: Maybe<Scalars['String']>;
  creatorWidget: Scalars['Boolean'];
  creatorWidgetPosition: EcommWidgetPosition;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  displayName?: Maybe<Scalars['String']>;
  features: Features;
  hideCommissionsWithPending?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ilpFilterSegment?: Maybe<SegmentType>;
  integrationType: IntegrationType;
  keepUnattributedOrders?: Maybe<Scalars['Boolean']>;
  lcRelativeLogoUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  otpLoginUrl?: Maybe<Scalars['String']>;
  pagedEcommStoreAmbassadors?: Maybe<PagedPublicEcommAmbassador>;
  pagedProductPickerCollectionProducts?: Maybe<PagedProductPickerCollectionType>;
  productIdRegex: Scalars['String'];
  productPickerCollections?: Maybe<Array<ProductPickerCollectionType>>;
  productUrlTemplate: Scalars['String'];
  relationship?: Maybe<StoreAmbassadorRelationship>;
  relationshipLegacy?: Maybe<AmbassadorHub>;
  sellsSubscriptions?: Maybe<Scalars['Boolean']>;
  shopUrl: Scalars['String'];
  shortCode: Scalars['String'];
  siteId: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  storeLogoUrl?: Maybe<Scalars['String']>;
};


export type CommerceCloudIntegrationPagedEcommStoreAmbassadorsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
};


export type CommerceCloudIntegrationPagedProductPickerCollectionProductsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
  productPickerCollectionId?: InputMaybe<Scalars['ID']>;
};

export type CommissionFailedCustomerEvent = BaseCustomerEvent & {
  __typename: 'CommissionFailedCustomerEvent';
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type CommissionPaymentCustomerEvent = BaseCustomerEvent & {
  __typename: 'CommissionPaymentCustomerEvent';
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export enum CommissionPaymentSort {
  PaidAt = 'PAID_AT'
}

export type CommissionPaymentType = Node & {
  __typename: 'CommissionPaymentType';
  amount: Scalars['Float'];
  failedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionSuccessCustomerEvent = BaseCustomerEvent & {
  __typename: 'CommissionSuccessCustomerEvent';
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type CommissionTierInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  ratePercentage: Scalars['Float'];
};

export type CommissionTierType = Node & {
  __typename: 'CommissionTierType';
  affiliateMembersCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ratePercentage: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ContentFilterType = BaseFilterType & {
  __typename: 'ContentFilterType';
  entity: FilterEntity;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  value?: Maybe<ContentFilterValueType>;
};

export type ContentFilterValueType = {
  __typename: 'ContentFilterValueType';
  biography?: Maybe<KeywordsFilter>;
  campaignId?: Maybe<Scalars['ID']>;
  campaigns?: Maybe<LabelsFilter>;
  caption?: Maybe<KeywordsFilter>;
  challengeIds?: Maybe<LabelsFilter>;
  challengeMediaApproval?: Maybe<TagStatusFilter>;
  commissionTiers?: Maybe<LabelsFilter>;
  engagementRate?: Maybe<FloatRangeFilter>;
  expiredStories?: Maybe<Scalars['Boolean']>;
  followerCount?: Maybe<IntegerRangeFilter>;
  hashtag?: Maybe<KeywordsFilter>;
  impressions?: Maybe<IntegerRangeFilter>;
  labels?: Maybe<LabelsFilter>;
  maxPostedAt?: Maybe<DateTimeRangeFilter>;
  mediaType?: Maybe<MediaTypeFilter>;
  messageDate?: Maybe<DateInclusionExclusionTypeFilter>;
  messageKeywords?: Maybe<KeywordsFilter>;
  messageSendingMethod?: Maybe<MessageSendingMethodsFilter>;
  messageTemplate?: Maybe<LabelsFilter>;
  minPostedAt?: Maybe<DateTimeRangeFilter>;
  postCount?: Maybe<IntegerRangeFilter>;
  postType?: Maybe<PostTypeFilter>;
  postedAt?: Maybe<DateTimeRangeFilter>;
  segments?: Maybe<LabelsFilter>;
  tagStatus?: Maybe<TagStatusFilter>;
  unavailableMedia?: Maybe<Scalars['Boolean']>;
  wasCaptionMentioned?: Maybe<Scalars['Boolean']>;
  wasHashtagMentioned?: Maybe<Scalars['Boolean']>;
  wasStoryMentioned?: Maybe<Scalars['Boolean']>;
  wasTagged?: Maybe<Scalars['Boolean']>;
};

export type CreateAutomation = {
  __typename: 'CreateAutomation';
  automation?: Maybe<Automation>;
  ok: Scalars['Boolean'];
};

export type CreateAutomationInput = {
  ecommIntegrationId?: InputMaybe<Scalars['ID']>;
  filterId?: InputMaybe<Scalars['ID']>;
  integrationId?: InputMaybe<Scalars['ID']>;
  rewardId?: InputMaybe<Scalars['ID']>;
  socialAccountId?: InputMaybe<Scalars['ID']>;
  triggerType: TriggerType;
};

export type CreateBillingPortalSession = {
  __typename: 'CreateBillingPortalSession';
  link?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateCampaign = {
  __typename: 'CreateCampaign';
  campaign?: Maybe<CampaignType>;
  ok: Scalars['Boolean'];
};

export type CreateCampaignInput = {
  autoApproved?: InputMaybe<Scalars['Boolean']>;
  currencyCode?: InputMaybe<CurrencyCodeEnum>;
  ecommIntegrationId?: InputMaybe<Scalars['ID']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  igSocialAccountId?: InputMaybe<Scalars['ID']>;
  integrationId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  programType?: InputMaybe<ProgramTypeEnum>;
  requiresVerification?: InputMaybe<Scalars['Boolean']>;
  socialAccountId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  ttSocialAccountId?: InputMaybe<Scalars['ID']>;
};

export type CreateChallenge = {
  __typename: 'CreateChallenge';
  challenge?: Maybe<ChallengeType>;
  ok: Scalars['Boolean'];
};

export type CreateChallengeInput = {
  additionalCriteria?: InputMaybe<AdditionalCriteriaInputType>;
  endedAt?: InputMaybe<Scalars['DateTime']>;
  hashtags?: InputMaybe<Array<Scalars['String']>>;
  hashtagsCriteriaOperator: CriteriaOperatorEnum;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  keywordsCriteriaOperator: CriteriaOperatorEnum;
  name: Scalars['String'];
  paintedDoor?: InputMaybe<CreatePaintedDoorInput>;
  postTypes?: InputMaybe<Array<IgMediaPostType>>;
  programs?: InputMaybe<Array<Scalars['ID']>>;
  rewards?: InputMaybe<Array<CreateChallengeRewardInput>>;
  socialAccountIds: Array<Scalars['ID']>;
  startedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateChallengeRewardInput = {
  rewardId: Scalars['ID'];
  winningCriteria?: InputMaybe<ChallengeRewardCriteriaEnum>;
};

export type CreateCheckoutSession = {
  __typename: 'CreateCheckoutSession';
  link: Scalars['String'];
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateCustomerSourceJob = {
  __typename: 'CreateCustomerSourceJob';
  job?: Maybe<CustomerSourceJob>;
};

export type CreateFilter = {
  __typename: 'CreateFilter';
  filter?: Maybe<FilterType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateFulfillmentAccount = {
  __typename: 'CreateFulfillmentAccount';
  fulfillmentAccount?: Maybe<FulfillAccountType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateIntegration = {
  __typename: 'CreateIntegration';
  integration?: Maybe<IntegrationTypes>;
  ok: Scalars['Boolean'];
};

export type CreateLabel = {
  __typename: 'CreateLabel';
  label?: Maybe<LabelType>;
  mentions?: Maybe<Array<Mention>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateMessageTemplate = {
  __typename: 'CreateMessageTemplate';
  messageTemplate?: Maybe<MessageTemplate>;
  ok: Scalars['Boolean'];
};

export type CreateOrganization = {
  __typename: 'CreateOrganization';
  ok: Scalars['Boolean'];
  organization?: Maybe<Organization>;
};

export type CreateOrganizationInput = {
  billingEmail: Scalars['String'];
  emvCpmUsd?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  productId: Scalars['ID'];
};

export type CreatePaintedDoorInput = {
  automatedMessageContent?: InputMaybe<Scalars['String']>;
  automatedMessageDeliveryMethods?: InputMaybe<Array<ChallengeMessageDeliveryMethodEnum>>;
  automatedMessageTriggers?: InputMaybe<Array<ChallengeMessageTriggerEnum>>;
  briefContent?: InputMaybe<Scalars['String']>;
  briefDeliveryMethods?: InputMaybe<Array<ChallengeBriefDeliveryMethodEnum>>;
  winningInstructions?: InputMaybe<Array<ChallengeAutomatedRewardInstructions>>;
};

export type CreateParticipant = {
  __typename: 'CreateParticipant';
  ok?: Maybe<Scalars['Boolean']>;
  participant?: Maybe<ProgramParticipantType>;
};

export type CreateParticipantInput = {
  email: Scalars['String'];
  igUsername?: InputMaybe<Scalars['String']>;
  ttUsername?: InputMaybe<Scalars['String']>;
};

export type CreateParticipantSegment = {
  __typename: 'CreateParticipantSegment';
  ok?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<ProgramParticipantType>>;
  segment?: Maybe<SegmentType>;
};

export type CreateProgramContentRules = {
  __typename: 'CreateProgramContentRules';
  contentRules?: Maybe<ProgramContentRulesType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateReward = {
  __typename: 'CreateReward';
  ok?: Maybe<Scalars['Boolean']>;
  reward?: Maybe<RewardType>;
};

export type CreateRewardInputType = {
  bluecoreCampaignId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  deliveryMethod?: InputMaybe<Scalars['String']>;
  emailTemplateName?: InputMaybe<Scalars['String']>;
  emailTemplateVariableName?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  hasDiscountCode: Scalars['Boolean'];
  integrationId?: InputMaybe<Scalars['ID']>;
  isDmReward?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  tierId?: InputMaybe<Scalars['String']>;
  tremendousCampaignId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type CreateSegment = {
  __typename: 'CreateSegment';
  customers?: Maybe<Array<CustomerType>>;
  ok?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<SegmentType>;
};

export type CreateShopifyAuthUrl = {
  __typename: 'CreateShopifyAuthUrl';
  authUrl?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CreateShopifyIntegration = {
  __typename: 'CreateShopifyIntegration';
  integration?: Maybe<ShopifyIntegration>;
  ok: Scalars['Boolean'];
};

export type CreateTikTokSocialAccountHashtag = {
  __typename: 'CreateTikTokSocialAccountHashtag';
  ok: Scalars['Boolean'];
  trackedHashtag?: Maybe<SocialAccountHashtagType>;
};

export type CreateUser = {
  __typename: 'CreateUser';
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export enum CriteriaOperatorEnum {
  All = 'ALL',
  Any = 'ANY'
}

export enum CurrencyCodeEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ved = 'VED',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

export enum CustomFieldEnumType {
  Address = 'ADDRESS',
  Address_2 = 'ADDRESS_2',
  Checkbox = 'CHECKBOX',
  City = 'CITY',
  Country = 'COUNTRY',
  Date = 'DATE',
  FirstName = 'FIRST_NAME',
  Instagram = 'INSTAGRAM',
  LastName = 'LAST_NAME',
  Phone = 'PHONE',
  Select = 'SELECT',
  SmsConsent = 'SMS_CONSENT',
  State = 'STATE',
  TermsConditions = 'TERMS_CONDITIONS',
  Text = 'TEXT',
  Tiktok = 'TIKTOK',
  ZipCode = 'ZIP_CODE'
}

export type CustomFieldFilter = {
  __typename: 'CustomFieldFilter';
  customFieldId: Scalars['ID'];
  dateValue?: Maybe<DateRangeFilter>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type CustomFieldFilterInput = {
  customFieldId: Scalars['ID'];
  dateValue?: InputMaybe<DateFilterInput>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomFieldInput = {
  id?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CustomFieldSavedFilterInput = {
  customFieldId: Scalars['ID'];
  dateValue?: InputMaybe<DateRangeFilterInput>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomFieldType = Node & {
  __typename: 'CustomFieldType';
  id: Scalars['ID'];
  options?: Maybe<Array<Scalars['String']>>;
  position?: Maybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  title: Scalars['String'];
  type: CustomFieldEnumType;
};

export type CustomFieldsFilter = {
  __typename: 'CustomFieldsFilter';
  all?: Maybe<Array<CustomFieldFilter>>;
  any?: Maybe<Array<CustomFieldFilter>>;
};

export type CustomFieldsFilterInput = {
  all?: InputMaybe<Array<InputMaybe<CustomFieldFilterInput>>>;
  any?: InputMaybe<Array<InputMaybe<CustomFieldFilterInput>>>;
};

export type CustomFieldsSavedFilterInput = {
  all?: InputMaybe<Array<InputMaybe<CustomFieldSavedFilterInput>>>;
  any?: InputMaybe<Array<InputMaybe<CustomFieldSavedFilterInput>>>;
};

export type CustomStoreIntegration = EcommStore & Integration & Node & {
  __typename: 'CustomStoreIntegration';
  brandMessage?: Maybe<Scalars['String']>;
  capabilities: Array<IntegrationCapabilityEnum>;
  creatorWidget: Scalars['Boolean'];
  creatorWidgetPosition: EcommWidgetPosition;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  displayName?: Maybe<Scalars['String']>;
  features: Features;
  hideCommissionsWithPending?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ilpFilterSegment?: Maybe<SegmentType>;
  integrationType: IntegrationType;
  keepUnattributedOrders?: Maybe<Scalars['Boolean']>;
  lcRelativeLogoUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  otpLoginUrl?: Maybe<Scalars['String']>;
  pagedEcommStoreAmbassadors?: Maybe<PagedPublicEcommAmbassador>;
  pagedProductPickerCollectionProducts?: Maybe<PagedProductPickerCollectionType>;
  productIdRegex: Scalars['String'];
  productPickerCollections?: Maybe<Array<ProductPickerCollectionType>>;
  productSourceIntegration?: Maybe<Integration>;
  relationship?: Maybe<StoreAmbassadorRelationship>;
  relationshipLegacy?: Maybe<AmbassadorHub>;
  shopUrl: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  storeLogoUrl?: Maybe<Scalars['String']>;
};


export type CustomStoreIntegrationPagedEcommStoreAmbassadorsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
};


export type CustomStoreIntegrationPagedProductPickerCollectionProductsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
  productPickerCollectionId?: InputMaybe<Scalars['ID']>;
};

export type CustomerCustomFieldType = Node & {
  __typename: 'CustomerCustomFieldType';
  customField: CustomFieldType;
  dateValue?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  storefrontLayout?: Maybe<StorefrontLayoutEnum>;
  value?: Maybe<Scalars['String']>;
};

export type CustomerEvent = AddCustomerNoteEvent | AddLabelCustomerEvent | AddSegmentCustomerEvent | AttributedSaleCustomerEvent | ChallengeApprovalStatusCustomerEvent | CommissionFailedCustomerEvent | CommissionPaymentCustomerEvent | CommissionSuccessCustomerEvent | FulfilledTierCustomerEvent | MentionCustomerEvent | MentionStatusCustomerEvent | ParticipantStatusCustomerEvent | PickedProductsCustomerEvent | PlacedOrderCustomerEvent | RegisterProgramCustomerEvent | RemoveCustomerNoteEvent | RemoveLabelCustomerEvent | RemoveSegmentCustomerEvent | SentMessageCustomerEvent | SetCommissionTierCustomerEvent;

export enum CustomerEventType {
  AddLabel = 'ADD_LABEL',
  AddNote = 'ADD_NOTE',
  AddSegment = 'ADD_SEGMENT',
  AttributedSale = 'ATTRIBUTED_SALE',
  ChallengeApprovalStatus = 'CHALLENGE_APPROVAL_STATUS',
  CommissionFailed = 'COMMISSION_FAILED',
  CommissionPayment = 'COMMISSION_PAYMENT',
  CommissionSuccess = 'COMMISSION_SUCCESS',
  FulfilledTier = 'FULFILLED_TIER',
  Mention = 'MENTION',
  MentionStatus = 'MENTION_STATUS',
  ParticipantStatus = 'PARTICIPANT_STATUS',
  PickedProducts = 'PICKED_PRODUCTS',
  PlacedOrder = 'PLACED_ORDER',
  RegisterProgram = 'REGISTER_PROGRAM',
  RemovedLabel = 'REMOVED_LABEL',
  RemovedSegment = 'REMOVED_SEGMENT',
  RemoveNote = 'REMOVE_NOTE',
  SentMessage = 'SENT_MESSAGE',
  SetCommissionTier = 'SET_COMMISSION_TIER',
  TaggedProducts = 'TAGGED_PRODUCTS'
}

export enum CustomerExportFieldEnum {
  Address = 'ADDRESS',
  Address_2 = 'ADDRESS_2',
  AvgEngagementRate = 'AVG_ENGAGEMENT_RATE',
  City = 'CITY',
  Conversion = 'CONVERSION',
  Country = 'COUNTRY',
  DiscountCodes = 'DISCOUNT_CODES',
  Email = 'EMAIL',
  ExternalId = 'EXTERNAL_ID',
  FirstName = 'FIRST_NAME',
  IgAvatar = 'IG_AVATAR',
  IgFollowers = 'IG_FOLLOWERS',
  IgUsername = 'IG_USERNAME',
  Impressions = 'IMPRESSIONS',
  Landings = 'LANDINGS',
  LastName = 'LAST_NAME',
  MaxPostDate = 'MAX_POST_DATE',
  MemberSince = 'MEMBER_SINCE',
  OwedCommissions = 'OWED_COMMISSIONS',
  PaidCommissions = 'PAID_COMMISSIONS',
  PendingCommissions = 'PENDING_COMMISSIONS',
  Phone = 'PHONE',
  PostCount = 'POST_COUNT',
  Revenue = 'REVENUE',
  Sales = 'SALES',
  SignUp = 'SIGN_UP',
  State = 'STATE',
  Status = 'STATUS',
  StorefrontUrl = 'STOREFRONT_URL',
  TotalOrders = 'TOTAL_ORDERS',
  TtAvatar = 'TT_AVATAR',
  TtFollowers = 'TT_FOLLOWERS',
  TtUsername = 'TT_USERNAME',
  Zip = 'ZIP'
}

export type CustomerExternalIdInput = {
  externalId: Scalars['String'];
  integrationId: Scalars['ID'];
};

export type CustomerExternalIdentifier = Node & {
  __typename: 'CustomerExternalIdentifier';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  integration: Integration;
};

export enum CustomerFieldEnumType {
  Address = 'ADDRESS',
  Address_2 = 'ADDRESS_2',
  Checkbox = 'CHECKBOX',
  City = 'CITY',
  Country = 'COUNTRY',
  Date = 'DATE',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  Instagram = 'INSTAGRAM',
  LastName = 'LAST_NAME',
  Phone = 'PHONE',
  SmsConsent = 'SMS_CONSENT',
  State = 'STATE',
  TermsConditions = 'TERMS_CONDITIONS',
  Tiktok = 'TIKTOK',
  ZipCode = 'ZIP_CODE'
}

export type CustomerFieldInput = {
  type?: InputMaybe<CustomerFieldEnumType>;
  value?: InputMaybe<Scalars['String']>;
};

export type CustomerFilterInput = {
  approvedAt?: InputMaybe<DateTimeFilterInput>;
  avgEngagementRate?: InputMaybe<IntegerRangeFilterInput>;
  biography?: InputMaybe<BiographyFilterInput>;
  campaignId?: InputMaybe<IdFilterInput>;
  campaigns?: InputMaybe<CampaignsFilterInput>;
  challengeIds?: InputMaybe<ChallengesFilterInput>;
  challengeMediaApproval?: InputMaybe<TagStatusFilterInput>;
  customFields?: InputMaybe<CustomFieldsFilterInput>;
  email?: InputMaybe<KeywordFilterInput>;
  expiredStories?: InputMaybe<Scalars['Boolean']>;
  followerCount?: InputMaybe<IntegerRangeFilterInput>;
  hasIgUser?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<IdFilterInput>;
  impressions?: InputMaybe<IntegerRangeFilterInput>;
  includePremembership?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<LabelsFilterInput>;
  maxPostedAt?: InputMaybe<DateTimeFilterInput>;
  maxRewardedAt?: InputMaybe<DateTimeFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  messageDate?: InputMaybe<DateInclusionExclusionFilterInput>;
  messageKeywords?: InputMaybe<KeywordsFilterInput>;
  messageSendingMethod?: InputMaybe<MessageSendingMethodsFilterInput>;
  messageTemplate?: InputMaybe<IdFilterInput>;
  minPostedAt?: InputMaybe<DateTimeFilterInput>;
  noteCategories?: InputMaybe<IdFilterInput>;
  noteCreatedAt?: InputMaybe<DateTimeFilterInput>;
  notes?: InputMaybe<KeywordFilterInput>;
  postCount?: InputMaybe<IntegerRangeFilterInput>;
  postType?: InputMaybe<PostTypeFilterInput>;
  postedAt?: InputMaybe<DateTimeFilterInput>;
  rewards?: InputMaybe<IdFilterInput>;
  segments?: InputMaybe<SegmentsFilterInput>;
  segmentsCampaigns?: InputMaybe<SegmentCampaignFilterInput>;
  socialAccountId?: InputMaybe<IdFilterInput>;
  tagStatus?: InputMaybe<TagStatusFilterInput>;
  username?: InputMaybe<KeywordFilterInput>;
  wasCaptionMentioned?: InputMaybe<Scalars['Boolean']>;
  wasHashtagMentioned?: InputMaybe<Scalars['Boolean']>;
  wasStoryMentioned?: InputMaybe<Scalars['Boolean']>;
  wasTagged?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerFilterType = BaseFilterType & {
  __typename: 'CustomerFilterType';
  entity: FilterEntity;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  value?: Maybe<CustomerFilterValueType>;
};

export type CustomerFilterValueType = {
  __typename: 'CustomerFilterValueType';
  approvedAt?: Maybe<DateTimeRangeFilter>;
  avgEngagementRate?: Maybe<FloatRangeFilter>;
  biography?: Maybe<KeywordsFilter>;
  campaigns?: Maybe<LabelsFilter>;
  challengeIds?: Maybe<LabelsFilter>;
  challengeMediaApproval?: Maybe<TagStatusFilter>;
  commissionRate?: Maybe<FloatRangeFilter>;
  commissionTiers?: Maybe<LabelsFilter>;
  commissionsEarned?: Maybe<IntegerRangeFilter>;
  conversionRate?: Maybe<IntegerRangeFilter>;
  customFields?: Maybe<CustomFieldsFilter>;
  dateRange?: Maybe<DateTimeRangeFilter>;
  email?: Maybe<KeywordsFilter>;
  followerCount?: Maybe<IntegerRangeFilter>;
  impressions?: Maybe<IntegerRangeFilter>;
  labels?: Maybe<LabelsFilter>;
  linkViews?: Maybe<IntegerRangeFilter>;
  maxPostedAt?: Maybe<DateTimeRangeFilter>;
  mediaType?: Maybe<MediaTypeFilter>;
  messageDate?: Maybe<DateInclusionExclusionTypeFilter>;
  messageKeywords?: Maybe<KeywordsFilter>;
  messageSendingMethod?: Maybe<MessageSendingMethodsFilter>;
  messageTemplate?: Maybe<LabelsFilter>;
  minPostedAt?: Maybe<DateTimeRangeFilter>;
  noteCategories?: Maybe<IdFilter>;
  noteCreatedAt?: Maybe<DateTimeRangeFilter>;
  notes?: Maybe<KeywordsFilter>;
  paymentPeriods?: Maybe<IdFilter>;
  postCount?: Maybe<IntegerRangeFilter>;
  postType?: Maybe<PostTypeFilter>;
  postedAt?: Maybe<DateTimeRangeFilter>;
  referredOrders?: Maybe<IntegerRangeFilter>;
  referredSalesRevenue?: Maybe<IntegerRangeFilter>;
  rewards?: Maybe<IdFilter>;
  segments?: Maybe<LabelsFilter>;
  tagStatus?: Maybe<TagStatusFilter>;
  username?: Maybe<KeywordsFilter>;
  wasCaptionMentioned?: Maybe<Scalars['Boolean']>;
  wasHashtagMentioned?: Maybe<Scalars['Boolean']>;
  wasStoryMentioned?: Maybe<Scalars['Boolean']>;
  wasTagged?: Maybe<Scalars['Boolean']>;
};

export type CustomerInput = {
  email?: InputMaybe<Scalars['String']>;
  igUsername: Scalars['String'];
};

export type CustomerMentionStats = {
  __typename: 'CustomerMentionStats';
  avgEngagementRate: Scalars['Float'];
  commentCount: Scalars['Int'];
  emv: Scalars['Float'];
  impressions: Scalars['Int'];
  maxPostDate?: Maybe<Scalars['DateTime']>;
  minPostDate?: Maybe<Scalars['DateTime']>;
  postCount: Scalars['Int'];
  ugcValue: Scalars['Float'];
};

export type CustomerMentionStatsFilters = {
  campaignId?: InputMaybe<IdFilterInput>;
  campaigns?: InputMaybe<CampaignsFilterInput>;
  challengeIds?: InputMaybe<ChallengesFilterInput>;
  challengeMediaApproval?: InputMaybe<TagStatusFilterInput>;
  expiredStories?: InputMaybe<Scalars['Boolean']>;
  includePremembership?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<LabelsFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  postType?: InputMaybe<PostTypeFilterInput>;
  postedAt?: InputMaybe<DateTimeFilterInput>;
  socialAccountId?: InputMaybe<IdFilterInput>;
  tagStatus?: InputMaybe<TagStatusFilterInput>;
  wasCaptionMentioned?: InputMaybe<Scalars['Boolean']>;
  wasHashtagMentioned?: InputMaybe<Scalars['Boolean']>;
  wasStoryMentioned?: InputMaybe<Scalars['Boolean']>;
  wasTagged?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerNoteCategory = Node & {
  __typename: 'CustomerNoteCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CustomerNoteEvent = {
  customerNote: CustomerNoteType;
};

export type CustomerNoteType = Node & {
  __typename: 'CustomerNoteType';
  author: UserType;
  category?: Maybe<CustomerNoteCategory>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  note: Scalars['String'];
};

export enum CustomerSort {
  AvgEngagementRate = 'AVG_ENGAGEMENT_RATE',
  Comments = 'COMMENTS',
  Followers = 'FOLLOWERS',
  Impressions = 'IMPRESSIONS',
  MaxPostDate = 'MAX_POST_DATE',
  Posts = 'POSTS'
}

export type CustomerSourceJob = Node & {
  __typename: 'CustomerSourceJob';
  completedAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startedAt?: Maybe<Scalars['DateTime']>;
  uploadUrl: PresignedUrlAndFields;
};

export type CustomerSourceJobInput = {
  campaignId?: InputMaybe<Scalars['ID']>;
  segmentId?: InputMaybe<Scalars['ID']>;
};

export type CustomerType = {
  __typename: 'CustomerType';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  ambassadorStats?: Maybe<AmbassadorStats>;
  ambassadorStatsTimeseries?: Maybe<Array<AmbassadorStatsTimeseries>>;
  challenges?: Maybe<Array<ChallengeType>>;
  city?: Maybe<Scalars['String']>;
  commissionPayments?: Maybe<PagedCommissionPaymentType>;
  country?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<CustomerCustomFieldType>>;
  email?: Maybe<Scalars['String']>;
  events?: Maybe<PagedCustomerEventType>;
  externalIdentifiers: Array<CustomerExternalIdentifier>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  igUser?: Maybe<IgUser>;
  lastName?: Maybe<Scalars['String']>;
  lcId: Scalars['String'];
  mentionStats?: Maybe<CustomerMentionStats>;
  mentions?: Maybe<PagedMentionType>;
  notes?: Maybe<PagedCustomerNoteType>;
  paymentPeriods?: Maybe<Array<PaymentPeriod>>;
  phone?: Maybe<Scalars['String']>;
  programParticipants: Array<ProgramParticipantType>;
  segments?: Maybe<Array<SegmentType>>;
  state?: Maybe<Scalars['String']>;
  ttUser?: Maybe<TtUser>;
  zipCode?: Maybe<Scalars['String']>;
};


export type CustomerTypeAmbassadorStatsArgs = {
  getPending?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AmbassadorStatsInput>;
};


export type CustomerTypeAmbassadorStatsTimeseriesArgs = {
  timeDimension?: InputMaybe<TimeDimension>;
  timezone: Scalars['String'];
  where?: InputMaybe<AmbassadorStatsTimeseriesInput>;
};


export type CustomerTypeChallengesArgs = {
  rewardableOnly?: InputMaybe<Scalars['Boolean']>;
};


export type CustomerTypeCommissionPaymentsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CommissionPaymentSort>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type CustomerTypeEventsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<CustomerEventType>;
  limit?: InputMaybe<Scalars['Int']>;
  mediaId?: InputMaybe<Scalars['ID']>;
  socialAccountId: Scalars['ID'];
};


export type CustomerTypeMentionStatsArgs = {
  where?: InputMaybe<CustomerMentionStatsFilters>;
};


export type CustomerTypeMentionsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  socialAccountId: Scalars['ID'];
  where?: InputMaybe<MentionFilterInput>;
};


export type CustomerTypeNotesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type DateFilterInput = {
  gte?: InputMaybe<Scalars['Date']>;
  lt?: InputMaybe<Scalars['Date']>;
};

export type DateInclusionExclusionFilterInput = {
  exclude?: InputMaybe<DateTimeRangeFilterInput>;
  require?: InputMaybe<DateTimeRangeFilterInput>;
};

export type DateInclusionExclusionTypeFilter = {
  __typename: 'DateInclusionExclusionTypeFilter';
  exclude?: Maybe<DateTimeRangeFilter>;
  require?: Maybe<DateTimeRangeFilter>;
};

export type DateRangeFilter = AbsoluteDateRangeFilter | RelativeDateRangeFilter;

export type DateRangeFilterBase = {
  rangeType: DateRangeFilterType;
};

export type DateRangeFilterInput = {
  gte?: InputMaybe<Scalars['Date']>;
  isExclude?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Date']>;
  offset?: InputMaybe<Scalars['Int']>;
  rangeType?: InputMaybe<DateRangeFilterType>;
  unit?: InputMaybe<DateRangeFilterUnits>;
  value?: InputMaybe<Scalars['Int']>;
};

export enum DateRangeFilterType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE'
}

export enum DateRangeFilterUnits {
  Days = 'DAYS',
  Hours = 'HOURS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
  Years = 'YEARS'
}

export type DateTimeFilterInput = {
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeRange = {
  __typename: 'DateTimeRange';
  rangeEnd: Scalars['DateTime'];
  rangeStart: Scalars['DateTime'];
};

export type DateTimeRangeFilter = AbsoluteDateTimeRangeFilter | RelativeDateRangeFilter;

export type DateTimeRangeFilterInput = {
  gte?: InputMaybe<Scalars['DateTime']>;
  isExclude?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  offset?: InputMaybe<Scalars['Int']>;
  rangeType?: InputMaybe<DateRangeFilterType>;
  unit?: InputMaybe<DateRangeFilterUnits>;
  value?: InputMaybe<Scalars['Int']>;
};

export type DeactivateProgramEcommDiscountCode = {
  __typename: 'DeactivateProgramEcommDiscountCode';
  ecommDiscountCode?: Maybe<EcommDiscountCodeType>;
  ok: Scalars['Boolean'];
};

export type DeleteAllDiscountRedeemCodes = {
  __typename: 'DeleteAllDiscountRedeemCodes';
  ok: Scalars['Boolean'];
};

export type DeleteAmbassadorCollection = {
  __typename: 'DeleteAmbassadorCollection';
  ok: Scalars['Boolean'];
};

export type DeleteAmbassadorCollectionProduct = {
  __typename: 'DeleteAmbassadorCollectionProduct';
  ok: Scalars['Boolean'];
};

export type DeleteChallenge = {
  __typename: 'DeleteChallenge';
  ok: Scalars['Boolean'];
};

export type DeleteCommissionTier = {
  __typename: 'DeleteCommissionTier';
  ok: Scalars['Boolean'];
};

export type DeleteDiscountRedeemCodes = {
  __typename: 'DeleteDiscountRedeemCodes';
  ok: Scalars['Boolean'];
};

export type DeleteFilter = {
  __typename: 'DeleteFilter';
  filter?: Maybe<FilterType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteFulfillmentAccount = {
  __typename: 'DeleteFulfillmentAccount';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteLabel = {
  __typename: 'DeleteLabel';
  label?: Maybe<LabelType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteMessageTemplate = {
  __typename: 'DeleteMessageTemplate';
  messageTemplate?: Maybe<MessageTemplate>;
  ok: Scalars['Boolean'];
};

export type DeleteReward = {
  __typename: 'DeleteReward';
  ok?: Maybe<Scalars['Boolean']>;
  reward?: Maybe<RewardType>;
};

export type DeleteRewardCodes = {
  __typename: 'DeleteRewardCodes';
  ok: Scalars['Boolean'];
  reward?: Maybe<RewardType>;
};

export type DeleteSegment = {
  __typename: 'DeleteSegment';
  ok?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<SegmentType>;
};

export type DeleteUser = {
  __typename: 'DeleteUser';
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export type DiscountedPrice = {
  __typename: 'DiscountedPrice';
  discount: EcommDiscountCodeType;
  price: Scalars['Float'];
};

export type DiscountedPriceRange = {
  __typename: 'DiscountedPriceRange';
  discount: EcommDiscountCodeType;
  range: PriceRange;
};

export type DuplicateChallenge = {
  __typename: 'DuplicateChallenge';
  challenge?: Maybe<ChallengeType>;
  ok: Scalars['Boolean'];
};

export type EarnedReward = {
  __typename: 'EarnedReward';
  discountCode?: Maybe<Scalars['String']>;
  earnedAt: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EcommCustomerSegmentType = Node & {
  __typename: 'EcommCustomerSegmentType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum EcommDiscountAppliesToEnum {
  Collection = 'COLLECTION',
  Product = 'PRODUCT'
}

export enum EcommDiscountClassEnum {
  Order = 'ORDER',
  Product = 'PRODUCT'
}

export enum EcommDiscountCodeFormatEnum {
  FirstLastName = 'FIRST_LAST_NAME',
  SocialUsername = 'SOCIAL_USERNAME'
}

export type EcommDiscountCodeInput = {
  appliesOnEachItem?: InputMaybe<Scalars['Boolean']>;
  appliesOnFirstTimePurchase?: InputMaybe<Scalars['Boolean']>;
  appliesOncePerCustomer?: InputMaybe<Scalars['Boolean']>;
  appliesTo?: InputMaybe<EcommDiscountAppliesToEnum>;
  codeFormat: EcommDiscountCodeFormatEnum;
  codeType: EcommDiscountCodeTypeEnum;
  collectionIds?: InputMaybe<Array<Scalars['ID']>>;
  combinesWithOrderDiscounts: Scalars['Boolean'];
  combinesWithProductDiscounts?: InputMaybe<Scalars['Boolean']>;
  combinesWithShippingDiscounts: Scalars['Boolean'];
  customerEligibility?: InputMaybe<EcommDiscountCustomerEligibilityEnum>;
  discountClass: EcommDiscountClassEnum;
  ecommCustomerSegmentIds?: InputMaybe<Array<Scalars['ID']>>;
  overrideMessage?: InputMaybe<Scalars['Boolean']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  purchaseType?: InputMaybe<EcommDiscountPurchaseTypeEnum>;
  usageLimit?: InputMaybe<Scalars['Int']>;
  value: Scalars['Float'];
  valueType: EcommDiscountCodeValueTypeEnum;
};

export type EcommDiscountCodeType = Node & {
  __typename: 'EcommDiscountCodeType';
  appliesOnEachItem?: Maybe<Scalars['Boolean']>;
  appliesOnFirstTimePurchase?: Maybe<Scalars['Boolean']>;
  appliesOnOneTimePurchase: Scalars['Boolean'];
  appliesOnSubscription: Scalars['Boolean'];
  appliesOncePerCustomer?: Maybe<Scalars['Boolean']>;
  codeFormat: EcommDiscountCodeFormatEnum;
  codeType: EcommDiscountCodeTypeEnum;
  collections?: Maybe<Array<CollectionType>>;
  combinesWithOrderDiscounts: Scalars['Boolean'];
  combinesWithProductDiscounts?: Maybe<Scalars['Boolean']>;
  combinesWithShippingDiscounts: Scalars['Boolean'];
  customerEligibility?: Maybe<EcommDiscountCustomerEligibilityEnum>;
  customerSegments?: Maybe<Array<EcommCustomerSegmentType>>;
  discountClass: EcommDiscountClassEnum;
  endsAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  overrideMessage?: Maybe<Scalars['Boolean']>;
  products?: Maybe<Array<Product>>;
  program?: Maybe<ProgramType>;
  startsAt: Scalars['DateTime'];
  usageLimit?: Maybe<Scalars['Int']>;
  value: Scalars['Float'];
  valueType: EcommDiscountCodeValueTypeEnum;
};

export enum EcommDiscountCodeTypeEnum {
  Affiliate = 'AFFILIATE',
  Automatic = 'AUTOMATIC'
}

export enum EcommDiscountCodeValueTypeEnum {
  DiscountAmount = 'DISCOUNT_AMOUNT',
  Percentage = 'PERCENTAGE'
}

export enum EcommDiscountCustomerEligibilityEnum {
  AllCustomers = 'ALL_CUSTOMERS',
  SpecificCustomerSegments = 'SPECIFIC_CUSTOMER_SEGMENTS'
}

export enum EcommDiscountPurchaseTypeEnum {
  Both = 'BOTH',
  OneTimePurchase = 'ONE_TIME_PURCHASE',
  Subscription = 'SUBSCRIPTION'
}

export type EcommDiscountRedeemCodeType = Node & {
  __typename: 'EcommDiscountRedeemCodeType';
  code: Scalars['String'];
  ecommDiscountCode: EcommDiscountCodeType;
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  value: Scalars['Float'];
  valueType: EcommDiscountCodeValueTypeEnum;
};

export enum EcommMediaSource {
  LocalFiles = 'LOCAL_FILES',
  SocialPosts = 'SOCIAL_POSTS'
}

export type EcommStore = {
  brandMessage?: Maybe<Scalars['String']>;
  creatorWidget: Scalars['Boolean'];
  creatorWidgetPosition: EcommWidgetPosition;
  displayName?: Maybe<Scalars['String']>;
  features: Features;
  hideCommissionsWithPending?: Maybe<Scalars['Boolean']>;
  keepUnattributedOrders?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  otpLoginUrl?: Maybe<Scalars['String']>;
  pagedEcommStoreAmbassadors?: Maybe<PagedPublicEcommAmbassador>;
  pagedProductPickerCollectionProducts?: Maybe<PagedProductPickerCollectionType>;
  productPickerCollections?: Maybe<Array<ProductPickerCollectionType>>;
  relationship?: Maybe<StoreAmbassadorRelationship>;
  relationshipLegacy?: Maybe<AmbassadorHub>;
  slug?: Maybe<Scalars['String']>;
};


export type EcommStorePagedEcommStoreAmbassadorsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
};


export type EcommStorePagedProductPickerCollectionProductsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
  productPickerCollectionId?: InputMaybe<Scalars['ID']>;
};

/** EcommWebEvent types which should be persisted on the DB */
export enum EcommWebEventType {
  Clicked = 'CLICKED',
  DiscountRedeemed = 'DISCOUNT_REDEEMED',
  Landed = 'LANDED',
  NewCart = 'NEW_CART',
  Ordered = 'ORDERED',
  OrderIntake = 'ORDER_INTAKE',
  PageView = 'PAGE_VIEW'
}

export enum EcommWidgetPosition {
  CenterBottom = 'CENTER_BOTTOM',
  LeftBottom = 'LEFT_BOTTOM',
  LeftCenter = 'LEFT_CENTER',
  RightBottom = 'RIGHT_BOTTOM',
  RightCenter = 'RIGHT_CENTER'
}

export type EmarsysIntegration = Integration & Node & {
  __typename: 'EmarsysIntegration';
  apiSecret: Scalars['String'];
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type EventsInput = {
  platform?: InputMaybe<SocialPlatformEnum>;
  rewardedAt?: InputMaybe<DateTimeFilterInput>;
  rewards?: InputMaybe<IdFilterInput>;
  username?: InputMaybe<KeywordFilterInput>;
};

/**
 * A facebook user.  Maps to
 * https://developers.facebook.com/docs/graph-api/reference/user/
 */
export type FbUser = Node & {
  __typename: 'FBUser';
  /** Account tied to FBUser. If null, then the user authed through a campaign. */
  account?: Maybe<AccountType>;
  availableIgAccounts?: Maybe<Array<IgAccount>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  /** Estimated time their token expires. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Has a valid token to the facebook api */
  isValid: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** Social accounts fb user has access to. */
  socialAccounts?: Maybe<Array<IgSocialAccount>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum FeatureValuesEnum {
  Beta = 'BETA',
  Off = 'OFF',
  On = 'ON'
}

export type Features = {
  __typename: 'Features';
  autoNavbar: FeatureValuesEnum;
  creatorApp: FeatureValuesEnum;
  creatorWidget: FeatureValuesEnum;
  heroBlock: FeatureValuesEnum;
  multiCollections: FeatureValuesEnum;
  otpLogin: FeatureValuesEnum;
  productTagging: FeatureValuesEnum;
};

export enum FilterEntity {
  Content = 'CONTENT',
  Customer = 'CUSTOMER'
}

export type FilterType = ContentFilterType | CustomerFilterType;

export type FloatRangeFilter = {
  __typename: 'FloatRangeFilter';
  gte?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
};

export type FloatRangeFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type FulfillAccountType = {
  __typename: 'FulfillAccountType';
  accountType: FulfillmentAccountType;
  apiSecret?: Maybe<Scalars['String']>;
  apiUrl?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  integrationName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lastPush?: Maybe<Scalars['DateTime']>;
  namespace?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  smsConsentList?: Maybe<Scalars['String']>;
};

export type FulfilledTierCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & {
  __typename: 'FulfilledTierCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  fulfillment?: Maybe<FulfillmentType>;
  id: Scalars['ID'];
};

export enum FulfillmentAccountType {
  Bluecore = 'BLUECORE',
  Braze = 'BRAZE',
  Emarsys = 'EMARSYS',
  Growave = 'GROWAVE',
  Klaviyo = 'KLAVIYO',
  LoyaltyLion = 'LOYALTY_LION',
  Mandrill = 'MANDRILL',
  Ometria = 'OMETRIA',
  Sendinblue = 'SENDINBLUE',
  Smile = 'SMILE',
  Tremendous = 'TREMENDOUS',
  Webhook = 'WEBHOOK',
  Yotpo = 'YOTPO',
  Zaius = 'ZAIUS'
}

export type FulfillmentType = Node & {
  __typename: 'FulfillmentType';
  challenge?: Maybe<ChallengeType>;
  customer?: Maybe<CustomerType>;
  id: Scalars['ID'];
  programTier?: Maybe<ProgramTierType>;
  reward?: Maybe<RewardType>;
  rewardDiscountCode?: Maybe<RewardDiscountCodeType>;
};

export type GoogleIntegration = Integration & Node & {
  __typename: 'GoogleIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  name: Scalars['String'];
};

export type GrowaveIntegration = Integration & Node & {
  __typename: 'GrowaveIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
};

/** A hashtag that was in a mention for a social account */
export type Hashtag = Node & {
  __typename: 'Hashtag';
  hashtag: Scalars['String'];
  id: Scalars['ID'];
  mentionStats: HashtagMentionStats;
};


/** A hashtag that was in a mention for a social account */
export type HashtagMentionStatsArgs = {
  where?: InputMaybe<HashtagMentionsFilter>;
};

export type HashtagMentionStats = {
  __typename: 'HashtagMentionStats';
  count: Scalars['Int'];
  ranking?: Maybe<Scalars['Int']>;
};

/** Filters to apply to _mentions_ on hashtags */
export type HashtagMentionsFilter = {
  campaigns?: InputMaybe<CampaignsFilterInput>;
  challengeIds?: InputMaybe<ChallengesFilterInput>;
  challengeMediaApproval?: InputMaybe<TagStatusFilterInput>;
  includePremembership?: InputMaybe<Scalars['Boolean']>;
  postType?: InputMaybe<PostTypeFilterInput>;
  postedAt: DateTimeFilterInput;
  segments?: InputMaybe<SegmentsFilterInput>;
  segmentsCampaigns?: InputMaybe<SegmentCampaignFilterInput>;
  tagStatus?: InputMaybe<TagStatusFilterInput>;
  wasCaptionMentioned?: InputMaybe<Scalars['Boolean']>;
  wasHashtagMentioned?: InputMaybe<Scalars['Boolean']>;
  wasStoryMentioned?: InputMaybe<Scalars['Boolean']>;
  wasTagged?: InputMaybe<Scalars['Boolean']>;
};

export type IgAccount = {
  __typename: 'IGAccount';
  platformId: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type IgMedia = Media & Node & {
  __typename: 'IGMedia';
  caption?: Maybe<Scalars['String']>;
  challengeMedia?: Maybe<Array<Maybe<ChallengeMediaType>>>;
  children?: Maybe<Array<IgMediaChild>>;
  fromAuthedApi: Scalars['Boolean'];
  hashtags?: Maybe<Array<MediaHashtag>>;
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  lcMediaUrl?: Maybe<Scalars['String']>;
  lcThumbnailUrl?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  postType: IgMediaPostType;
  postedAt: Scalars['DateTime'];
  poster: IgUser;
  programMedia?: Maybe<Array<ProgramMediaType>>;
  stats?: Maybe<IgMediaStats>;
};

export type IgMediaChild = {
  __typename: 'IGMediaChild';
  id: Scalars['ID'];
  lcMediaUrl?: Maybe<Scalars['String']>;
  lcThumbnailUrl?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  platformId?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export enum IgMediaPostType {
  Feed = 'FEED',
  Reels = 'REELS',
  Story = 'STORY'
}

export type IgMediaStats = MediaStats & Node & {
  __typename: 'IGMediaStats';
  commentCount?: Maybe<Scalars['Int']>;
  emv: Scalars['Float'];
  engagementRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  impressions?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  replies?: Maybe<Scalars['Int']>;
  ugcValue: Scalars['Float'];
};

export type IgMention = Mention & Node & {
  __typename: 'IGMention';
  id: Scalars['ID'];
  isPastLimit: Scalars['Boolean'];
  labels?: Maybe<Array<LabelType>>;
  media: IgMedia;
  socialAccount: IgSocialAccount;
  status: MentionStatus;
  wasCaptionMentioned: Scalars['Boolean'];
  wasDirectMessaged?: Maybe<Scalars['Boolean']>;
  wasHashtagMentioned: Scalars['Boolean'];
  wasRewarded?: Maybe<Scalars['Boolean']>;
  wasStoryMentioned: Scalars['Boolean'];
  wasTagged: Scalars['Boolean'];
};

export type IgSocialAccount = Node & SocialAccount & {
  __typename: 'IGSocialAccount';
  campaigns?: Maybe<Array<CampaignType>>;
  challenges?: Maybe<ChallengeTypePaged>;
  customer?: Maybe<CustomerType>;
  customers?: Maybe<PagedCustomerType>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fbUsers?: Maybe<Array<FbUser>>;
  hashtags: PagedHashtags;
  id: Scalars['ID'];
  invalidAt?: Maybe<Scalars['DateTime']>;
  lastPull?: Maybe<Scalars['DateTime']>;
  mentionStatsTimeseries?: Maybe<Array<SocialDataPoint>>;
  messageTemplate?: Maybe<MessageTemplate>;
  messageTemplates?: Maybe<PagedMessageTemplate>;
  platformId?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<ProgramType>>;
  sentMessageStats?: Maybe<SentMessageStats>;
  sentMessages: PagedMessages;
  socialUser: IgUser;
  stats?: Maybe<StatsType>;
  syncProgress?: Maybe<SyncProgressType>;
};


export type IgSocialAccountChallengesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type IgSocialAccountCustomerArgs = {
  id: Scalars['ID'];
};


export type IgSocialAccountCustomersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CustomerSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type IgSocialAccountHashtagsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<HashtagMentionsFilter>;
};


export type IgSocialAccountMentionStatsTimeseriesArgs = {
  timeDimension: TimeDimension;
  timezone: Scalars['String'];
  where: MentionFilterInput;
};


export type IgSocialAccountMessageTemplateArgs = {
  id: Scalars['ID'];
};


export type IgSocialAccountMessageTemplatesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MessageTemplateSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<MessageTemplateFilterInput>;
};


export type IgSocialAccountSentMessageStatsArgs = {
  customersWhere?: InputMaybe<CustomerFilterInput>;
  where?: InputMaybe<MessageFilter>;
};


export type IgSocialAccountSentMessagesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  customersWhere?: InputMaybe<CustomerFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageFilter>;
};


export type IgSocialAccountStatsArgs = {
  where: MentionFilterInput;
};

export type IgSocialAccountInput = {
  id: Scalars['ID'];
  platform: Scalars['String'];
};

export type IgUser = Node & SocialUser & {
  __typename: 'IGUser';
  avatarUrl?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerType>;
  externalUrl?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  storiesIgSocialAccounts?: Maybe<Array<IgSocialAccount>>;
  username: Scalars['String'];
};

export type IdFilter = {
  __typename: 'IdFilter';
  any?: Maybe<Array<Scalars['ID']>>;
};

export type IdFilterInput = {
  all?: InputMaybe<Array<Array<Scalars['ID']>>>;
  any?: InputMaybe<Array<Scalars['ID']>>;
  none?: InputMaybe<Array<Scalars['ID']>>;
};

export type Identity = AmbassadorUser | LegacyAmbassadorUser | UserType;

export type ImportCustomers = {
  __typename: 'ImportCustomers';
  customers?: Maybe<Array<CustomerType>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ImportParticipants = {
  __typename: 'ImportParticipants';
  ok: Scalars['Boolean'];
};

export type IntegerRangeFilter = {
  __typename: 'IntegerRangeFilter';
  gte?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
};

export type IntegerRangeFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
};

export type Integration = {
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  name: Scalars['String'];
};

export enum IntegrationCapabilityEnum {
  CreateAffiliateCodes = 'CREATE_AFFILIATE_CODES',
  CreateAutomaticDiscounts = 'CREATE_AUTOMATIC_DISCOUNTS',
  DiscountCode = 'DISCOUNT_CODE',
  ExportCustomers = 'EXPORT_CUSTOMERS',
  ExportMedia = 'EXPORT_MEDIA',
  ImportCustomers = 'IMPORT_CUSTOMERS',
  ImportOrders = 'IMPORT_ORDERS',
  ImportProducts = 'IMPORT_PRODUCTS',
  ImportRefunds = 'IMPORT_REFUNDS',
  Reward = 'REWARD',
  Storefront = 'STOREFRONT'
}

export type IntegrationCapabilityFilterInput = {
  all?: InputMaybe<Array<IntegrationCapabilityEnum>>;
  any?: InputMaybe<Array<IntegrationCapabilityEnum>>;
};

export enum IntegrationDeliveryMethodEnum {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type IntegrationFilterInput = {
  capabilities?: InputMaybe<IntegrationCapabilityFilterInput>;
};

export enum IntegrationType {
  Bluecore = 'BLUECORE',
  Braze = 'BRAZE',
  CommerceCloud = 'COMMERCE_CLOUD',
  CustomStore = 'CUSTOM_STORE',
  Emarsys = 'EMARSYS',
  Google = 'GOOGLE',
  Growave = 'GROWAVE',
  Klaviyo = 'KLAVIYO',
  LoyaltyLion = 'LOYALTY_LION',
  Mandrill = 'MANDRILL',
  OcapiCustomSource = 'OCAPI_CUSTOM_SOURCE',
  Ometria = 'OMETRIA',
  Refersion = 'REFERSION',
  Sendinblue = 'SENDINBLUE',
  Sftp = 'SFTP',
  SftpPull = 'SFTP_PULL',
  Shopify = 'SHOPIFY',
  Smile = 'SMILE',
  Tremendous = 'TREMENDOUS',
  Webhook = 'WEBHOOK',
  Yotpo = 'YOTPO',
  YotpoViz = 'YOTPO_VIZ',
  Zaius = 'ZAIUS'
}

export type IntegrationTypes = BluecoreIntegration | BrazeIntegration | CommerceCloudIntegration | CustomStoreIntegration | EmarsysIntegration | GoogleIntegration | GrowaveIntegration | KlaviyoIntegration | LoyaltyLionIntegration | MandrillIntegration | OmetriaIntegration | RefersionIntegration | SftpIntegration | SftpPullIntegration | SalesforceOcapiIntegration | SendinblueIntegration | ShopifyIntegration | SmileIntegration | TremendousIntegration | WebhookIntegration | YotpoIntegration | YotpoVizIntegration | ZaiusIntegration;

export type IntegrationsFilterInput = {
  any: Array<FulfillmentAccountType>;
};

export type InvalidateIntegration = {
  __typename: 'InvalidateIntegration';
  integration?: Maybe<IntegrationTypes>;
  ok: Scalars['Boolean'];
};

export type InvisibleMedia = {
  __typename: 'InvisibleMedia';
  id: Scalars['ID'];
  labels?: Maybe<Array<LabelType>>;
  mediaType?: Maybe<MediaTypes>;
  mediaUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  platform?: Maybe<SocialPlatformEnum>;
  postType?: Maybe<IgMediaPostType>;
  postedAt: Scalars['DateTime'];
  programMedias?: Maybe<Array<ProgramMediaType>>;
};

export type KeywordFilterInput = {
  keywords: Array<Scalars['String']>;
};

export type KeywordsFilter = {
  __typename: 'KeywordsFilter';
  all?: Maybe<Array<Array<Scalars['String']>>>;
  any?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  none?: Maybe<Array<Scalars['String']>>;
};

export type KeywordsFilterInput = {
  all?: InputMaybe<Array<Array<Scalars['String']>>>;
  any?: InputMaybe<Array<Scalars['String']>>;
  none?: InputMaybe<Array<Scalars['String']>>;
};

export type KlaviyoIntegration = Integration & Node & {
  __typename: 'KlaviyoIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
  smsConsentList?: Maybe<Scalars['String']>;
};

export type LabelCustomerEvent = {
  label: LabelType;
  mention: Mention;
};

export type LabelFilterInput = {
  search?: InputMaybe<KeywordFilterInput>;
};

export type LabelLimit = {
  __typename: 'LabelLimit';
  hasHitLimit: Scalars['Boolean'];
  isUnlimited: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  used: Scalars['Int'];
};

export enum LabelSort {
  Name = 'NAME',
  RecentlyUsed = 'RECENTLY_USED'
}

export type LabelType = {
  __typename: 'LabelType';
  account?: Maybe<AccountType>;
  id: Scalars['Float'];
  isRejectionReason: Scalars['Boolean'];
  name: Scalars['String'];
};

export type LabelsFilter = {
  __typename: 'LabelsFilter';
  all?: Maybe<Array<Array<Scalars['ID']>>>;
  any?: Maybe<Array<Scalars['ID']>>;
  none?: Maybe<Array<Scalars['ID']>>;
};

export type LabelsFilterInput = {
  all?: InputMaybe<Array<Array<Scalars['ID']>>>;
  any?: InputMaybe<Array<Scalars['ID']>>;
  none?: InputMaybe<Array<Scalars['ID']>>;
};

export type LegacyAmbassadorUser = Node & {
  __typename: 'LegacyAmbassadorUser';
  id: Scalars['ID'];
};

export type LoyaltyLionIntegration = Integration & Node & {
  __typename: 'LoyaltyLionIntegration';
  apiSecret: Scalars['String'];
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type LtvBreakdown = {
  __typename: 'LtvBreakdown';
  customers: Scalars['Int'];
  ltvAfter: Scalars['Float'];
  ltvBefore: Scalars['Float'];
  ltvNonmember: Scalars['Float'];
};

export type MandrillIntegration = Integration & Node & {
  __typename: 'MandrillIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  fromEmail: Scalars['String'];
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type Media = {
  caption?: Maybe<Scalars['String']>;
  challengeMedia?: Maybe<Array<Maybe<ChallengeMediaType>>>;
  hashtags?: Maybe<Array<MediaHashtag>>;
  id: Scalars['ID'];
  lcMediaUrl?: Maybe<Scalars['String']>;
  lcThumbnailUrl?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  postedAt: Scalars['DateTime'];
  poster: SocialUser;
  programMedia?: Maybe<Array<ProgramMediaType>>;
  stats?: Maybe<MediaStats>;
};

export type MediaHashtag = Node & {
  __typename: 'MediaHashtag';
  hashtag: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type MediaStats = {
  commentCount?: Maybe<Scalars['Int']>;
  emv: Scalars['Float'];
  engagementRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  impressions?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  replies?: Maybe<Scalars['Int']>;
  ugcValue: Scalars['Float'];
};

export enum MediaTypeEnum {
  CarouselAlbum = 'CAROUSEL_ALBUM',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type MediaTypeFilter = {
  __typename: 'MediaTypeFilter';
  any?: Maybe<Array<MediaTypeEnum>>;
};

export type MediaTypeFilterInput = {
  any: Array<MediaTypeEnum>;
};

export enum MediaTypes {
  CarouselAlbum = 'CAROUSEL_ALBUM',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type Mention = {
  id: Scalars['ID'];
  isPastLimit: Scalars['Boolean'];
  labels?: Maybe<Array<LabelType>>;
  media: Media;
  socialAccount: SocialAccount;
  status: MentionStatus;
  wasCaptionMentioned: Scalars['Boolean'];
  wasDirectMessaged?: Maybe<Scalars['Boolean']>;
  wasHashtagMentioned: Scalars['Boolean'];
  wasRewarded?: Maybe<Scalars['Boolean']>;
  wasStoryMentioned: Scalars['Boolean'];
  wasTagged: Scalars['Boolean'];
};

export type MentionCustomerEvent = BaseCustomerEvent & {
  __typename: 'MentionCustomerEvent';
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mention: Mention;
};

export type MentionFilterInput = {
  campaignId?: InputMaybe<Scalars['ID']>;
  campaigns?: InputMaybe<CampaignsFilterInput>;
  caption?: InputMaybe<KeywordFilterInput>;
  challengeIds?: InputMaybe<ChallengesFilterInput>;
  challengeMediaApproval?: InputMaybe<TagStatusFilterInput>;
  engagementRate?: InputMaybe<FloatRangeFilterInput>;
  expiredStories?: InputMaybe<Scalars['Boolean']>;
  followerCount?: InputMaybe<IntegerRangeFilterInput>;
  hashtag?: InputMaybe<KeywordFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  impressions?: InputMaybe<IntegerRangeFilterInput>;
  includePremembership?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<LabelsFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  messageDate?: InputMaybe<DateInclusionExclusionFilterInput>;
  messageKeywords?: InputMaybe<KeywordsFilterInput>;
  messageSendingMethod?: InputMaybe<MessageSendingMethodsFilterInput>;
  messageTemplate?: InputMaybe<IdFilterInput>;
  postCount?: InputMaybe<IntegerRangeFilterInput>;
  postType?: InputMaybe<PostTypeFilterInput>;
  postedAt?: InputMaybe<DateTimeFilterInput>;
  segments?: InputMaybe<LabelsFilterInput>;
  segmentsCampaigns?: InputMaybe<SegmentCampaignFilterInput>;
  socialAccountId?: InputMaybe<IdFilterInput>;
  tagStatus?: InputMaybe<TagStatusFilterInput>;
  unavailableMedia?: InputMaybe<Scalars['Boolean']>;
  wasCaptionMentioned?: InputMaybe<Scalars['Boolean']>;
  wasHashtagMentioned?: InputMaybe<Scalars['Boolean']>;
  wasStoryMentioned?: InputMaybe<Scalars['Boolean']>;
  wasTagged?: InputMaybe<Scalars['Boolean']>;
};

export type MentionHistory = Node & {
  __typename: 'MentionHistory';
  id: Scalars['ID'];
  mention: Mention;
  newStatus: MentionStatus;
  oldStatus: MentionStatus;
};

export enum MentionSort {
  CreatedAt = 'CREATED_AT',
  EngagementRate = 'ENGAGEMENT_RATE',
  Impressions = 'IMPRESSIONS',
  PostedAt = 'POSTED_AT'
}

export type MentionStats = {
  emv: Scalars['Float'];
  engagement: Scalars['Float'];
  impressions: Scalars['Float'];
  posts: Scalars['Int'];
  ugcValue: Scalars['Float'];
};

export enum MentionStatus {
  OfficialReview = 'OFFICIAL_REVIEW',
  Rejected = 'REJECTED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type MentionStatusCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & {
  __typename: 'MentionStatusCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mentionHistory: MentionHistory;
};

export enum MentionTypes {
  Caption = 'CAPTION',
  Story = 'STORY',
  Tag = 'TAG'
}

export type MentionedTrigger = Node & {
  __typename: 'MentionedTrigger';
  filter?: Maybe<ContentFilterType>;
  id: Scalars['ID'];
  socialAccount: SocialAccount;
};

export type Message = Node & {
  __typename: 'Message';
  createdAt: Scalars['DateTime'];
  fromSocialAccount: IgSocialAccount;
  fulfillment?: Maybe<FulfillmentType>;
  id: Scalars['ID'];
  messageTemplate?: Maybe<MessageTemplate>;
  messageText: Scalars['String'];
  replyMention: IgMention;
  sentBy?: Maybe<UserType>;
  socialUser: IgUser;
};

export type MessageFilter = {
  mentionId?: InputMaybe<Scalars['ID']>;
  messageKeywords?: InputMaybe<KeywordsFilterInput>;
  messageSendingMethod?: InputMaybe<MessageSendingMethodsFilterInput>;
  messageTemplate?: InputMaybe<IdFilterInput>;
  reward?: InputMaybe<IdFilterInput>;
  sentAt?: InputMaybe<DateInclusionExclusionFilterInput>;
};

export enum MessageSendingMethodsEnum {
  AutomaticSend = 'AUTOMATIC_SEND',
  ManualCustom = 'MANUAL_CUSTOM',
  ManualTemplates = 'MANUAL_TEMPLATES'
}

export type MessageSendingMethodsFilter = {
  __typename: 'MessageSendingMethodsFilter';
  any?: Maybe<Array<MessageSendingMethodsEnum>>;
  none?: Maybe<Array<MessageSendingMethodsEnum>>;
};

export type MessageSendingMethodsFilterInput = {
  any?: InputMaybe<Array<MessageSendingMethodsEnum>>;
  none?: InputMaybe<Array<MessageSendingMethodsEnum>>;
};

export type MessageTemplate = Node & {
  __typename: 'MessageTemplate';
  automation?: Maybe<Automation>;
  /** Number of mentions required to trigger an automation action */
  customerTriggerQuantity?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  reward?: Maybe<RewardType>;
  /** Skip sending the message within a time buffer, units hours. Only used if skip_send_message is not NEVER */
  skipActionTimeBuffer?: Maybe<Scalars['Int']>;
  /** Skip sending the message automatically rule, default is NEVER */
  skipSendMessage: SkipMessageTemplateActionEnum;
  stats?: Maybe<MessageTemplateStats>;
  template: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type MessageTemplateStatsArgs = {
  where?: InputMaybe<MessageTemplateStatsFilterInput>;
};

export type MessageTemplateAutomationInput = {
  /** Number of mentions to trigger and automation off of */
  customerTriggerQuantity?: InputMaybe<Scalars['Int']>;
  mentionFilter?: InputMaybe<SavedFilterInput>;
  /** Buffer for when to skip, in hours. null means always skip after receiving message */
  skipActionTimeBuffer?: InputMaybe<Scalars['Int']>;
  /** When to skip sending the message if they have gotten it, default to never skip */
  skipSendMessage?: InputMaybe<SkipMessageTemplateActionEnum>;
};

export type MessageTemplateFilterInput = {
  automationEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<KeywordFilterInput>;
};

export type MessageTemplateInput = {
  automation?: InputMaybe<MessageTemplateAutomationInput>;
  /** Is automation enabled or not */
  automationEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  rewardId?: InputMaybe<Scalars['ID']>;
  socialAccountId: Scalars['ID'];
  template: Scalars['String'];
};

export enum MessageTemplateSort {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  RecentlyUsed = 'RECENTLY_USED',
  UpdatedAt = 'UPDATED_AT'
}

export type MessageTemplateStats = {
  __typename: 'MessageTemplateStats';
  totalRecipients: Scalars['Int'];
};

export type MessageTemplateStatsFilterInput = {
  socialAccountId: Scalars['ID'];
};

export type MockEcommAmbassador = Node & {
  __typename: 'MockEcommAmbassador';
  ambassadorCollectionProducts?: Maybe<Array<Product>>;
  id: Scalars['ID'];
  productPickerCollections?: Maybe<Array<ProductPickerCollectionType>>;
};

export type Mutations = {
  __typename: 'Mutations';
  activateProgramEcommDiscountCode?: Maybe<ActivateProgramEcommDiscountCode>;
  addCustomerNote?: Maybe<AddCustomerNote>;
  addCustomerSegment?: Maybe<AddCustomerSegment>;
  addMentionLabel?: Maybe<AddMentionLabel>;
  /** Add a customer to a campaign */
  addParticipantCustomer?: Maybe<AddParticipantCustomer>;
  addParticipantSegment?: Maybe<AddParticipantSegment>;
  addStoryMention?: Maybe<AddStoryMention>;
  addTikTokSocialAccount?: Maybe<AddTikTokSocialAccount>;
  /** Authenticate a fb business user using the code returned from facebook login */
  authFbBusiness?: Maybe<AuthFbBusiness>;
  claimChallengeReviewLock?: Maybe<ClaimChallengeReviewLock>;
  claimProgramReviewLock?: Maybe<ClaimProgramReviewLock>;
  createAutomation?: Maybe<CreateAutomation>;
  createBillingPortalSession?: Maybe<CreateBillingPortalSession>;
  createCampaign?: Maybe<CreateCampaign>;
  createChallenge?: Maybe<CreateChallenge>;
  createCheckoutSession?: Maybe<CreateCheckoutSession>;
  createCustomerSourceJob?: Maybe<CreateCustomerSourceJob>;
  createFilter?: Maybe<CreateFilter>;
  createFulfillmentAccount?: Maybe<CreateFulfillmentAccount>;
  createIntegration?: Maybe<CreateIntegration>;
  createLabel?: Maybe<CreateLabel>;
  createMessageTemplate?: Maybe<CreateMessageTemplate>;
  createOrganization?: Maybe<CreateOrganization>;
  createParticipant?: Maybe<CreateParticipant>;
  createParticipantSegment?: Maybe<CreateParticipantSegment>;
  createProgramContentRules?: Maybe<CreateProgramContentRules>;
  createReward?: Maybe<CreateReward>;
  createSegment?: Maybe<CreateSegment>;
  createShopifyAuthUrl?: Maybe<CreateShopifyAuthUrl>;
  createShopifyIntegration?: Maybe<CreateShopifyIntegration>;
  createTikTokSocialAccountHashtag?: Maybe<CreateTikTokSocialAccountHashtag>;
  createUser?: Maybe<CreateUser>;
  deactivateProgramEcommDiscountCode?: Maybe<DeactivateProgramEcommDiscountCode>;
  deleteAllDiscountRedeemCodes?: Maybe<DeleteAllDiscountRedeemCodes>;
  deleteAmbassadorCollection?: Maybe<DeleteAmbassadorCollection>;
  deleteAmbassadorCollectionProduct?: Maybe<DeleteAmbassadorCollectionProduct>;
  deleteChallenge?: Maybe<DeleteChallenge>;
  deleteCommissionTier?: Maybe<DeleteCommissionTier>;
  deleteDiscountRedeemCodes?: Maybe<DeleteDiscountRedeemCodes>;
  deleteFilter?: Maybe<DeleteFilter>;
  deleteFulfillmentAccount?: Maybe<DeleteFulfillmentAccount>;
  deleteLabel?: Maybe<DeleteLabel>;
  deleteMessageTemplate?: Maybe<DeleteMessageTemplate>;
  deleteReward?: Maybe<DeleteReward>;
  deleteRewardCodes?: Maybe<DeleteRewardCodes>;
  deleteSegment?: Maybe<DeleteSegment>;
  deleteUser?: Maybe<DeleteUser>;
  duplicateChallenge?: Maybe<DuplicateChallenge>;
  importCustomers?: Maybe<ImportCustomers>;
  importParticipants?: Maybe<ImportParticipants>;
  invalidateIntegration?: Maybe<InvalidateIntegration>;
  refreshFbUser?: Maybe<RefreshFbUser>;
  registerCampaignParticipant?: Maybe<RegisterCampaignParticipant>;
  removeCustomerNote?: Maybe<RemoveCustomerNote>;
  removeCustomerSegment?: Maybe<RemoveCustomerSegment>;
  removeMentionLabel?: Maybe<RemoveMentionLabel>;
  removeParticipant?: Maybe<RemoveParticipant>;
  resendUserEmail?: Maybe<ResendUserEmail>;
  resendUserInvite?: Maybe<ResendUserInvite>;
  sendDirectMessage?: Maybe<SendDirectMessage>;
  sendFulfillment?: Maybe<SendFulfillment>;
  sendMonthlyEmail?: Maybe<SendMonthlyEmail>;
  setCampaignCommissionsEnabled?: Maybe<SetCampaignCommissionsEnabled>;
  setParticipantCommissionTier?: Maybe<SetParticipantCommissionTier>;
  signupAccount?: Maybe<SignupAccount>;
  syncIntegrationCollections?: Maybe<SyncIntegrationCollections>;
  syncIntegrationProducts?: Maybe<SyncIntegrationProducts>;
  syncSubscriptions?: Maybe<SyncSubscriptions>;
  testFulfillment?: Maybe<TestFulfillment>;
  testIntegration?: Maybe<TestIntegration>;
  toggleAmbassadorCollectionFeatureMedia?: Maybe<ToggleAmbassadorCollectionFeatureMedia>;
  toggleAmbassadorCollectionFeatureProduct?: Maybe<ToggleAmbassadorCollectionFeatureProduct>;
  toggleAmbassadorMediaVisible?: Maybe<ToggleAmbassadorMediaVisible>;
  togglePauseChallenge?: Maybe<TogglePauseChallenge>;
  /** Start tracking social accounts for a fb user, (saves social account) */
  trackSocialAccounts?: Maybe<TrackSocialAccounts>;
  updateAutomation?: Maybe<UpdateAutomation>;
  updateCampaignSettings?: Maybe<UpdateCampaignSettings>;
  updateChallenge?: Maybe<UpdateChallenge>;
  updateChallengeMediaApproval?: Maybe<UpdateChallengeMediaApproval>;
  updateCustomer?: Maybe<UpdateCustomer>;
  updateCustomerNote?: Maybe<UpdateCustomerNote>;
  updateFulfillmentAccount?: Maybe<UpdateFulfillmentAccount>;
  updateIntegration?: Maybe<UpdateIntegration>;
  updateLabel?: Maybe<UpdateLabel>;
  updateMentionStatus?: Maybe<UpdateMentionStatus>;
  updateMessageTemplate?: Maybe<UpdateMessageTemplate>;
  updateParticipantStatus?: Maybe<UpdateParticipantStatus>;
  updateProgramContentRules?: Maybe<UpdateProgramContentRules>;
  updateProgramsLtvStatuses?: Maybe<UpdateProgramsLtvStatuses>;
  updateReward?: Maybe<UpdateReward>;
  updateSegment?: Maybe<UpdateSegment>;
  /** Update IGSocialAccounts only */
  updateSocialAccounts?: Maybe<UpdateSocialAccounts>;
  updateSocialUsername?: Maybe<UpdateSocialUsername>;
  updateTikTokSocialAccount?: Maybe<UpdateTikTokSocialAccount>;
  updateTikTokSocialAccountHashtag?: Maybe<UpdateTikTokSocialAccountHashtag>;
  updateUser?: Maybe<UpdateUser>;
  upsertAmbassadorCollection?: Maybe<UpsertAmbassadorCollection>;
  upsertAmbassadorCollectionMediaProducts?: Maybe<UpsertAmbassadorCollectionMediaProducts>;
  upsertAmbassadorCollectionProducts?: Maybe<UpsertAmbassadorCollectionProducts>;
  upsertAmbassadorProfile?: Maybe<UpsertAmbassadorProfile>;
  upsertCommissionTier?: Maybe<UpsertCommissionTier>;
  upsertProductToAmbassadorCollections?: Maybe<UpsertProductToAmbassadorCollections>;
  upsertProgramEcommDiscountCode?: Maybe<UpsertProgramEcommDiscountCode>;
  upsertProgramParticipantEcommDiscountRedeemCode?: Maybe<UpsertProgramParticipantEcommDiscountRedeemCode>;
  upsertProgramTierGroup?: Maybe<UpsertProgramTierGroup>;
  validateAndSaveFbUser?: Maybe<ValidateAndSaveFbUser>;
};


export type MutationsActivateProgramEcommDiscountCodeArgs = {
  ecommDiscountCodeId: Scalars['ID'];
};


export type MutationsAddCustomerNoteArgs = {
  categoryLcid?: InputMaybe<Scalars['ID']>;
  customerId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationsAddCustomerSegmentArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  segmentId: Scalars['ID'];
  socialAccountId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<CustomerSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type MutationsAddMentionLabelArgs = {
  labelId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  mentionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  socialAccountId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<MentionSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<MentionFilterInput>;
};


export type MutationsAddParticipantCustomerArgs = {
  campaignId: Scalars['ID'];
  customerId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
};


export type MutationsAddParticipantSegmentArgs = {
  ambassadorStatsWhere?: InputMaybe<AmbassadorStatsInput>;
  campaignId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
  participantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  segmentId: Scalars['ID'];
  sortBy?: InputMaybe<ParticipantSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<ParticipantsInput>;
};


export type MutationsAddStoryMentionArgs = {
  igMediaId: Scalars['ID'];
  socialAccountId: Scalars['ID'];
};


export type MutationsAddTikTokSocialAccountArgs = {
  accessToken: Scalars['String'];
  followerCount: Scalars['Int'];
  imageUrl: Scalars['String'];
  platformId: Scalars['String'];
  ttSecUid: Scalars['String'];
  username: Scalars['String'];
};


export type MutationsAuthFbBusinessArgs = {
  addSocialAccounts?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationsClaimChallengeReviewLockArgs = {
  challengeId: Scalars['ID'];
};


export type MutationsClaimProgramReviewLockArgs = {
  programId: Scalars['ID'];
};


export type MutationsCreateAutomationArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  automation: CreateAutomationInput;
};


export type MutationsCreateCampaignArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  campaign: CreateCampaignInput;
};


export type MutationsCreateChallengeArgs = {
  challenge: CreateChallengeInput;
};


export type MutationsCreateCheckoutSessionArgs = {
  productId: Scalars['ID'];
};


export type MutationsCreateCustomerSourceJobArgs = {
  job?: InputMaybe<CustomerSourceJobInput>;
};


export type MutationsCreateFilterArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  entity: FilterEntity;
  name: Scalars['String'];
  value: SavedFilterInput;
};


export type MutationsCreateFulfillmentAccountArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountType: Scalars['String'];
  apiSecret?: InputMaybe<Scalars['String']>;
  apiUrl?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  guid?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  namespace?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  smsConsentList?: InputMaybe<Scalars['String']>;
};


export type MutationsCreateIntegrationArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  appKey?: InputMaybe<Scalars['String']>;
  appSecret?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  creatorWidget?: InputMaybe<Scalars['Boolean']>;
  creatorWidgetPosition?: InputMaybe<EcommWidgetPosition>;
  displayName?: InputMaybe<Scalars['String']>;
  escapeCharater?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  hostPublicKey?: InputMaybe<Scalars['String']>;
  igUsernameFields?: InputMaybe<Array<Scalars['String']>>;
  ilpFilterSegmentId?: InputMaybe<Scalars['ID']>;
  integrationType: IntegrationType;
  keepUnattributedOrders?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  lcRelativeLogoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  privateKey?: InputMaybe<Scalars['String']>;
  productIdRegex?: InputMaybe<Scalars['String']>;
  productMappings?: InputMaybe<Array<ProductColumnMapperInput>>;
  productSourceIntegrationId?: InputMaybe<Scalars['ID']>;
  productUrlTemplate?: InputMaybe<Scalars['String']>;
  publicKey?: InputMaybe<Scalars['String']>;
  refundMappings?: InputMaybe<Array<RefundColumnMapperInput>>;
  refundPrimaryKeySet?: InputMaybe<Array<RefundInternalKeyEnum>>;
  scope?: InputMaybe<Scalars['String']>;
  serverUrl?: InputMaybe<Scalars['String']>;
  shopUrl?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  ttUsernameFields?: InputMaybe<Array<Scalars['String']>>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationsCreateLabelArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  isRejectionReason?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  socialAccountId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<MentionSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<MentionFilterInput>;
};


export type MutationsCreateMessageTemplateArgs = {
  messageTemplateInput: MessageTemplateInput;
};


export type MutationsCreateOrganizationArgs = {
  organization: CreateOrganizationInput;
};


export type MutationsCreateParticipantArgs = {
  campaignId: Scalars['ID'];
  participant: CreateParticipantInput;
};


export type MutationsCreateParticipantSegmentArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  ambassadorStatsWhere?: InputMaybe<AmbassadorStatsInput>;
  campaignId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
  name: Scalars['String'];
  participantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sortBy?: InputMaybe<ParticipantSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<ParticipantsInput>;
};


export type MutationsCreateProgramContentRulesArgs = {
  data: ProgramContentRulesInput;
  programId: Scalars['ID'];
};


export type MutationsCreateRewardArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  codeUpload?: InputMaybe<CodeUploadInput>;
  reward?: InputMaybe<CreateRewardInputType>;
};


export type MutationsCreateSegmentArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  socialAccountId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<CustomerSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type MutationsCreateShopifyAuthUrlArgs = {
  shopName: Scalars['String'];
};


export type MutationsCreateShopifyIntegrationArgs = {
  queryParams: ShopifyQueryParamsInput;
  shopName: Scalars['String'];
};


export type MutationsCreateTikTokSocialAccountHashtagArgs = {
  active: Scalars['Boolean'];
  hashtag: Scalars['String'];
  socialAccountId: Scalars['ID'];
};


export type MutationsCreateUserArgs = {
  accountId: Scalars['ID'];
  email: Scalars['String'];
  roles?: InputMaybe<Array<UserRoleType>>;
};


export type MutationsDeactivateProgramEcommDiscountCodeArgs = {
  ecommDiscountCodeId: Scalars['ID'];
};


export type MutationsDeleteAllDiscountRedeemCodesArgs = {
  ecommDiscountCodeId: Scalars['ID'];
};


export type MutationsDeleteAmbassadorCollectionArgs = {
  ambassadorCollectionId: Scalars['ID'];
};


export type MutationsDeleteAmbassadorCollectionProductArgs = {
  ambassadorCollectionId?: InputMaybe<Scalars['ID']>;
  productId: Scalars['ID'];
};


export type MutationsDeleteChallengeArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteCommissionTierArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteDiscountRedeemCodesArgs = {
  ecommDiscountRedeemCodeId: Scalars['ID'];
};


export type MutationsDeleteFilterArgs = {
  filterId: Scalars['ID'];
};


export type MutationsDeleteFulfillmentAccountArgs = {
  fulfillmentAccountId: Scalars['ID'];
};


export type MutationsDeleteLabelArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteMessageTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteRewardArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteRewardCodesArgs = {
  rewardId: Scalars['ID'];
};


export type MutationsDeleteSegmentArgs = {
  segmentId: Scalars['ID'];
};


export type MutationsDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationsDuplicateChallengeArgs = {
  id: Scalars['ID'];
};


export type MutationsImportCustomersArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  customers: Array<CustomerInput>;
};


export type MutationsImportParticipantsArgs = {
  backdateToStartDate?: InputMaybe<Scalars['Boolean']>;
  campaignId: Scalars['ID'];
  participants: Array<CreateParticipantInput>;
};


export type MutationsInvalidateIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationsRefreshFbUserArgs = {
  fbToken: Scalars['String'];
};


export type MutationsRegisterCampaignParticipantArgs = {
  data: RegistrationInput;
  lcId: Scalars['String'];
};


export type MutationsRemoveCustomerNoteArgs = {
  noteId: Scalars['ID'];
};


export type MutationsRemoveCustomerSegmentArgs = {
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  segmentId: Scalars['ID'];
};


export type MutationsRemoveMentionLabelArgs = {
  labelId: Scalars['ID'];
  mentionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationsRemoveParticipantArgs = {
  participantId: Scalars['ID'];
};


export type MutationsResendUserInviteArgs = {
  userId: Scalars['ID'];
};


export type MutationsSendDirectMessageArgs = {
  customerId: Scalars['ID'];
  mediaId?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
  messageTemplateId?: InputMaybe<Scalars['ID']>;
  rewardId?: InputMaybe<Scalars['ID']>;
  socialAccountId: Scalars['ID'];
};


export type MutationsSendFulfillmentArgs = {
  ambassadorStatsWhere?: InputMaybe<AmbassadorStatsInput>;
  campaignId?: InputMaybe<Scalars['ID']>;
  challengeId?: InputMaybe<Scalars['ID']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  mediaId?: InputMaybe<Scalars['ID']>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
  offlineDelivery?: InputMaybe<Scalars['Boolean']>;
  rewardId: Scalars['ID'];
  sortBy?: InputMaybe<ParticipantSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<ParticipantsInput>;
};


export type MutationsSendMonthlyEmailArgs = {
  accountId: Scalars['ID'];
  email: Scalars['String'];
};


export type MutationsSetCampaignCommissionsEnabledArgs = {
  programId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationsSetParticipantCommissionTierArgs = {
  commissionTierId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
  participantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sortBy?: InputMaybe<ParticipantSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<ParticipantsInput>;
};


export type MutationsSignupAccountArgs = {
  industry?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationsSyncIntegrationCollectionsArgs = {
  id: Scalars['ID'];
};


export type MutationsSyncIntegrationProductsArgs = {
  id: Scalars['ID'];
};


export type MutationsTestFulfillmentArgs = {
  data: TestFulfillmentInput;
};


export type MutationsTestIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationsToggleAmbassadorCollectionFeatureMediaArgs = {
  ambassadorCollectionId?: InputMaybe<Scalars['ID']>;
  mediaId: Scalars['ID'];
};


export type MutationsToggleAmbassadorCollectionFeatureProductArgs = {
  ambassadorCollectionId?: InputMaybe<Scalars['ID']>;
  productId: Scalars['ID'];
};


export type MutationsToggleAmbassadorMediaVisibleArgs = {
  mediaId: Scalars['ID'];
};


export type MutationsTogglePauseChallengeArgs = {
  id: Scalars['ID'];
};


export type MutationsTrackSocialAccountsArgs = {
  fbUserId: Scalars['ID'];
  platformIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationsUpdateAutomationArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationsUpdateCampaignSettingsArgs = {
  campaignId: Scalars['ID'];
  data: CampaignSettingsInput;
};


export type MutationsUpdateChallengeArgs = {
  challenge: CreateChallengeInput;
  id: Scalars['ID'];
};


export type MutationsUpdateChallengeMediaApprovalArgs = {
  approval: MentionStatus;
  challengeId: Scalars['ID'];
  mediaId: Scalars['ID'];
};


export type MutationsUpdateCustomerArgs = {
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  customerFields?: InputMaybe<Array<CustomerFieldInput>>;
  customerId: Scalars['ID'];
  externalIds?: InputMaybe<Array<CustomerExternalIdInput>>;
};


export type MutationsUpdateCustomerNoteArgs = {
  noteId: Scalars['ID'];
  updatedCategoryLcid?: InputMaybe<Scalars['ID']>;
  updatedNote: Scalars['String'];
};


export type MutationsUpdateFulfillmentAccountArgs = {
  accountType: Scalars['String'];
  apiSecret?: InputMaybe<Scalars['String']>;
  apiUrl?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  fulfillmentAccountId: Scalars['ID'];
  guid?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  smsConsentList?: InputMaybe<Scalars['String']>;
};


export type MutationsUpdateIntegrationArgs = {
  appKey?: InputMaybe<Scalars['String']>;
  appSecret?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  creatorWidget?: InputMaybe<Scalars['Boolean']>;
  creatorWidgetPosition?: InputMaybe<EcommWidgetPosition>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  igUsernameFields?: InputMaybe<Array<Scalars['String']>>;
  ilpFilterSegmentId?: InputMaybe<Scalars['ID']>;
  keepUnattributedOrders?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  lcRelativeLogoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  productIdRegex?: InputMaybe<Scalars['String']>;
  productSourceIntegrationId?: InputMaybe<Scalars['ID']>;
  productUrlTemplate?: InputMaybe<Scalars['String']>;
  shopUrl?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  ttUsernameFields?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationsUpdateLabelArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationsUpdateMentionStatusArgs = {
  id: Scalars['ID'];
  status: MentionStatus;
};


export type MutationsUpdateMessageTemplateArgs = {
  messageTemplateInput: MessageTemplateInput;
  templateId: Scalars['ID'];
};


export type MutationsUpdateParticipantStatusArgs = {
  campaignId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
  participantIds?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<ParticipantSort>;
  sortDirection?: InputMaybe<SortDirection>;
  status: ParticipantStatus;
  where?: InputMaybe<ParticipantsInput>;
};


export type MutationsUpdateProgramContentRulesArgs = {
  data: ProgramContentRulesInput;
  programId: Scalars['ID'];
};


export type MutationsUpdateProgramsLtvStatusesArgs = {
  accountId: Scalars['ID'];
  programs?: InputMaybe<Array<ProgramLtvStatusInput>>;
};


export type MutationsUpdateRewardArgs = {
  codeUpload?: InputMaybe<CodeUploadInput>;
  id: Scalars['ID'];
  reward?: InputMaybe<UpdateRewardInputType>;
};


export type MutationsUpdateSegmentArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationsUpdateSocialAccountsArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  socialAccounts?: InputMaybe<Array<InputMaybe<IgSocialAccountInput>>>;
};


export type MutationsUpdateSocialUsernameArgs = {
  platform: SocialPlatformEnum;
  storeId?: InputMaybe<Scalars['ID']>;
  username: Scalars['String'];
};


export type MutationsUpdateTikTokSocialAccountArgs = {
  accessToken: Scalars['String'];
  followerCount: Scalars['Int'];
  imageUrl: Scalars['String'];
  ttSecUid: Scalars['String'];
  username: Scalars['String'];
};


export type MutationsUpdateTikTokSocialAccountHashtagArgs = {
  active: Scalars['Boolean'];
  socialAccountHashtagId: Scalars['ID'];
};


export type MutationsUpdateUserArgs = {
  roles?: InputMaybe<Array<UserRoleType>>;
  userId: Scalars['ID'];
};


export type MutationsUpsertAmbassadorCollectionArgs = {
  ambassadorCollectionId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  featuredAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  productIds: Array<Scalars['ID']>;
};


export type MutationsUpsertAmbassadorCollectionMediaProductsArgs = {
  mediaId: Scalars['ID'];
  productIdPlusCollectionIds?: InputMaybe<Array<InputMaybe<ProductIdPlusCollectionIdsInput>>>;
  productIds: Array<Scalars['ID']>;
};


export type MutationsUpsertAmbassadorCollectionProductsArgs = {
  productHandles?: InputMaybe<Array<Scalars['String']>>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationsUpsertAmbassadorProfileArgs = {
  profileData: AmbassadorProfileInput;
};


export type MutationsUpsertCommissionTierArgs = {
  data: CommissionTierInput;
  programId?: InputMaybe<Scalars['ID']>;
};


export type MutationsUpsertProductToAmbassadorCollectionsArgs = {
  collectionIds: Array<Scalars['ID']>;
  productId: Scalars['ID'];
};


export type MutationsUpsertProgramEcommDiscountCodeArgs = {
  data: EcommDiscountCodeInput;
  programId: Scalars['ID'];
};


export type MutationsUpsertProgramParticipantEcommDiscountRedeemCodeArgs = {
  code: Scalars['String'];
  customerId: Scalars['ID'];
  ecommDiscountRedeemCodeId?: InputMaybe<Scalars['ID']>;
  programId: Scalars['ID'];
};


export type MutationsUpsertProgramTierGroupArgs = {
  programId: Scalars['ID'];
  programTierGroupId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ProgramTierGroupStatus>;
  tiers?: InputMaybe<Array<ProgramTierInput>>;
};


export type MutationsValidateAndSaveFbUserArgs = {
  fbToken: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

export type OmetriaIntegration = Integration & Node & {
  __typename: 'OmetriaIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type OrderStats = {
  __typename: 'OrderStats';
  revenue: Scalars['Float'];
  totalCustomers: Scalars['Int'];
  totalOrders: Scalars['Int'];
};

export type OrderStatsTimeseries = {
  __typename: 'OrderStatsTimeseries';
  endDate: Scalars['Date'];
  revenue: Scalars['Float'];
  startDate: Scalars['Date'];
  totalCustomers: Scalars['Int'];
  totalOrders: Scalars['Int'];
};

export enum OrdersIncludeEnum {
  Allmember = 'ALLMEMBER',
  Member = 'MEMBER',
  Nonmember = 'NONMEMBER',
  Premember = 'PREMEMBER'
}

export type OrdersInput = {
  include?: InputMaybe<OrdersIncludeEnum>;
  rangeAt?: InputMaybe<DateTimeFilterInput>;
};

export type Organization = Node & {
  __typename: 'Organization';
  accounts: Array<AccountType>;
  /** Active app products for this organization. */
  activeProducts: Array<AccountProductName>;
  /** @deprecated this field shoud no longer be used as all orgs have this product */
  hasMessagingStoryReplies?: Maybe<Scalars['Boolean']>;
  hasTiktok?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** @deprecated Use social_account_limit. */
  igAccountLimit: SocialAccountLimit;
  isTrialing?: Maybe<Scalars['Boolean']>;
  labelLimit: LabelLimit;
  name: Scalars['String'];
  plans: Array<AccountPlanType>;
  postLimit?: Maybe<Scalars['Int']>;
  segmentLimit: SegmentLimit;
  socialAccountLimit: SocialAccountLimit;
  stripeCustomerId?: Maybe<Scalars['String']>;
  trialDaysRemaining?: Maybe<Scalars['Int']>;
  userLimit: UserLimit;
};

export type OwnedProgramHost = Node & ProgramHost & {
  __typename: 'OwnedProgramHost';
  allowFbAuth: Scalars['Boolean'];
  customFields?: Maybe<Array<CustomFieldType>>;
  id: Scalars['ID'];
  igRequired: Scalars['Boolean'];
  /** @deprecated Use username instead. */
  igUsername?: Maybe<Scalars['String']>;
  lcId: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  program?: Maybe<PublicProgram>;
  socialPlatform?: Maybe<SocialPlatformEnum>;
  socialPlatforms?: Maybe<Array<SocialPlatformEnum>>;
  ttRequired: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type PagedAccountType = {
  __typename: 'PagedAccountType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<AccountType>;
  total: Scalars['Int'];
};

export type PagedAmbassadorFeedItemType = {
  __typename: 'PagedAmbassadorFeedItemType';
  mediaCursor?: Maybe<Scalars['String']>;
  productCursor?: Maybe<Scalars['String']>;
  results: Array<AmbassadorFeedItemType>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedCampaignEventType = {
  __typename: 'PagedCampaignEventType';
  csvUrl?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  results: Array<CustomerEvent>;
  total: Scalars['Int'];
};

export type PagedCommissionPaymentType = {
  __typename: 'PagedCommissionPaymentType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<CommissionPaymentType>;
  total: Scalars['Int'];
};

export type PagedCustomerEventType = {
  __typename: 'PagedCustomerEventType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<CustomerEvent>;
  total: Scalars['Int'];
};

export type PagedCustomerNoteType = {
  __typename: 'PagedCustomerNoteType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<CustomerNoteType>;
  total: Scalars['Int'];
};

export type PagedCustomerType = {
  __typename: 'PagedCustomerType';
  csvUrl?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  results: Array<CustomerType>;
  total: Scalars['Int'];
};


export type PagedCustomerTypeCsvUrlArgs = {
  fields?: InputMaybe<Array<CustomerExportFieldEnum>>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
};

export type PagedDataInput = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type PagedHashtags = {
  __typename: 'PagedHashtags';
  cursor?: Maybe<Scalars['String']>;
  results: Array<Hashtag>;
  total: Scalars['Int'];
};

export type PagedLabelType = {
  __typename: 'PagedLabelType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<LabelType>;
  total: Scalars['Int'];
};

export type PagedMentionType = {
  __typename: 'PagedMentionType';
  csvUrl?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  results: Array<Mention>;
  total: Scalars['Int'];
};

export type PagedMessageTemplate = {
  __typename: 'PagedMessageTemplate';
  cursor?: Maybe<Scalars['String']>;
  results: Array<MessageTemplate>;
  total: Scalars['Int'];
};

export type PagedMessages = {
  __typename: 'PagedMessages';
  csvUrl?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  results: Array<Message>;
  total: Scalars['Int'];
};

export type PagedParticipants = {
  __typename: 'PagedParticipants';
  csvUrl?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  results: Array<ProgramParticipantType>;
  total: Scalars['Int'];
};


export type PagedParticipantsCsvUrlArgs = {
  ambassadorsWhere?: InputMaybe<AmbassadorStatsInput>;
  fields?: InputMaybe<Array<CustomerExportFieldEnum>>;
  mentionsWhere?: InputMaybe<CustomerMentionStatsFilters>;
};

export type PagedProductPickerCollectionType = {
  __typename: 'PagedProductPickerCollectionType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<Product>;
  total: Scalars['Int'];
};

export type PagedProgramType = {
  __typename: 'PagedProgramType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<ProgramType>;
  total: Scalars['Int'];
};

export type PagedPublicEcommAmbassador = {
  __typename: 'PagedPublicEcommAmbassador';
  cursor?: Maybe<Scalars['String']>;
  results: Array<PublicEcommAmbassador>;
  total: Scalars['Int'];
};

export type PagedRewardType = {
  __typename: 'PagedRewardType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<RewardType>;
  total: Scalars['Int'];
};

export type PagedSegmentType = {
  __typename: 'PagedSegmentType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<SegmentType>;
  total: Scalars['Int'];
};

export type PagedSocialAccountType = {
  __typename: 'PagedSocialAccountType';
  cursor?: Maybe<Scalars['String']>;
  results: Array<SocialAccount>;
  total: Scalars['Int'];
};

export type PagedStoreAmbassadorRelationship = {
  __typename: 'PagedStoreAmbassadorRelationship';
  results: Array<StoreAmbassadorRelationship>;
  total: Scalars['Int'];
};

export enum PagingDirection {
  Next = 'NEXT',
  Prev = 'PREV'
}

export type ParticipantAggregate = {
  count: Scalars['Int'];
};

export enum ParticipantSort {
  ApprovedAt = 'APPROVED_AT',
  AvgEngagementRate = 'AVG_ENGAGEMENT_RATE',
  Commissions = 'COMMISSIONS',
  ConversionRate = 'CONVERSION_RATE',
  Email = 'EMAIL',
  Followers = 'FOLLOWERS',
  FullName = 'FULL_NAME',
  IgUsername = 'IG_USERNAME',
  Impressions = 'IMPRESSIONS',
  Landings = 'LANDINGS',
  Orders = 'ORDERS',
  Posts = 'POSTS',
  Revenue = 'REVENUE',
  SignedUpAt = 'SIGNED_UP_AT',
  TtUsername = 'TT_USERNAME'
}

export type ParticipantStats = ParticipantAggregate & {
  __typename: 'ParticipantStats';
  count: Scalars['Int'];
};

export type ParticipantStatsTimeseries = ParticipantAggregate & {
  __typename: 'ParticipantStatsTimeseries';
  count: Scalars['Int'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export enum ParticipantStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ParticipantStatusCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & ProgramCustomerEvent & {
  __typename: 'ParticipantStatusCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  participantStatus: ParticipantStatus;
  programParticipant?: Maybe<ProgramParticipantType>;
};

export type ParticipantStatusFilterInput = {
  any: Array<ParticipantStatus>;
};

export type ParticipantsInput = {
  approvedAt?: InputMaybe<DateTimeFilterInput>;
  avgEngagementRate?: InputMaybe<IntegerRangeFilterInput>;
  biography?: InputMaybe<BiographyFilterInput>;
  challengeIds?: InputMaybe<ChallengesFilterInput>;
  challengeMediaApproval?: InputMaybe<TagStatusFilterInput>;
  commissionRate?: InputMaybe<FloatRangeFilterInput>;
  commissionTiers?: InputMaybe<IdFilterInput>;
  commissionsEarned?: InputMaybe<IntegerRangeFilterInput>;
  conversionRate?: InputMaybe<FloatRangeFilterInput>;
  customFields?: InputMaybe<CustomFieldsFilterInput>;
  email?: InputMaybe<KeywordFilterInput>;
  followerCount?: InputMaybe<IntegerRangeFilterInput>;
  hasOrders?: InputMaybe<Scalars['Boolean']>;
  impressions?: InputMaybe<IntegerRangeFilterInput>;
  labels?: InputMaybe<LabelsFilterInput>;
  linkViews?: InputMaybe<IntegerRangeFilterInput>;
  maxPostedAt?: InputMaybe<DateTimeFilterInput>;
  maxRewardedAt?: InputMaybe<DateTimeFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  messageDate?: InputMaybe<DateInclusionExclusionFilterInput>;
  messageKeywords?: InputMaybe<KeywordsFilterInput>;
  messageSendingMethod?: InputMaybe<MessageSendingMethodsFilterInput>;
  messageTemplate?: InputMaybe<IdFilterInput>;
  minPostedAt?: InputMaybe<DateTimeFilterInput>;
  noteCategories?: InputMaybe<IdFilterInput>;
  noteCreatedAt?: InputMaybe<DateTimeFilterInput>;
  notes?: InputMaybe<KeywordFilterInput>;
  participantIds?: InputMaybe<IdFilterInput>;
  platform?: InputMaybe<SocialPlatformEnum>;
  postCount?: InputMaybe<IntegerRangeFilterInput>;
  postType?: InputMaybe<PostTypeFilterInput>;
  referredOrders?: InputMaybe<IntegerRangeFilterInput>;
  referredSalesRevenue?: InputMaybe<IntegerRangeFilterInput>;
  rewards?: InputMaybe<IdFilterInput>;
  segments?: InputMaybe<SegmentsFilterInput>;
  segmentsCampaigns?: InputMaybe<SegmentCampaignFilterInput>;
  signedUpAt?: InputMaybe<DateTimeFilterInput>;
  status?: InputMaybe<ParticipantStatusFilterInput>;
  username?: InputMaybe<KeywordFilterInput>;
  wasCaptionMentioned?: InputMaybe<Scalars['Boolean']>;
  wasHashtagMentioned?: InputMaybe<Scalars['Boolean']>;
  wasStoryMentioned?: InputMaybe<Scalars['Boolean']>;
  wasTagged?: InputMaybe<Scalars['Boolean']>;
};

export enum PaymentDayEnum {
  FirstOfMonth = 'FIRST_OF_MONTH',
  Friday = 'FRIDAY',
  LastOfMonth = 'LAST_OF_MONTH',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  SpecificDay = 'SPECIFIC_DAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type PaymentPeriod = Node & {
  __typename: 'PaymentPeriod';
  endAt: Scalars['Date'];
  id: Scalars['ID'];
  paymentAt: Scalars['DateTime'];
  startAt: Scalars['Date'];
};

export enum PaymentPeriodEnum {
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum PaymentStatusEnum {
  Owed = 'OWED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type PayoutSettingsInput = {
  day: PaymentDayEnum;
  enabled: Scalars['Boolean'];
  includeShipping: Scalars['Boolean'];
  includeTax: Scalars['Boolean'];
  orderMaturationPeriodDays: Scalars['Int'];
  payoutIntegrationId?: InputMaybe<Scalars['ID']>;
  period: PaymentPeriodEnum;
  tremendousCampaignId?: InputMaybe<Scalars['ID']>;
};

export type PickedProductsCustomerEvent = BaseCustomerEvent & {
  __typename: 'PickedProductsCustomerEvent';
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  productEvents?: Maybe<Array<AmbassadorCollectionProductEvent>>;
};

export type PlacedOrderCustomerEvent = BaseCustomerEvent & {
  __typename: 'PlacedOrderCustomerEvent';
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  platformOrderId: Scalars['String'];
};

export type PostTypeFilter = {
  __typename: 'PostTypeFilter';
  any?: Maybe<Array<IgMediaPostType>>;
};

export type PostTypeFilterInput = {
  any: Array<IgMediaPostType>;
};

export type PresignedUrlAndFields = {
  __typename: 'PresignedUrlAndFields';
  fields: Array<Awss3PostField>;
  url: Scalars['String'];
};

export type PriceRange = {
  __typename: 'PriceRange';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type Product = Node & {
  __typename: 'Product';
  active: Scalars['Boolean'];
  ambassadorCollections?: Maybe<Array<AmbassadorCollection>>;
  brand?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  existsInAmbassadorCollections?: Maybe<Scalars['Boolean']>;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageSrc?: Maybe<Scalars['String']>;
  platformProductId: Scalars['String'];
  platformUpdatedAt?: Maybe<Scalars['DateTime']>;
  priceCurrencyCode?: Maybe<CurrencyCodeEnum>;
  prices?: Maybe<ProductPriceType>;
  productUrl?: Maybe<Scalars['String']>;
  promotionalCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductColumnMapperInput = {
  fileColumn: Scalars['String'];
  internalKey: ProductInternalKeyEnum;
};

export type ProductIdPlusCollectionIdsInput = {
  collectionIds?: InputMaybe<Array<Scalars['ID']>>;
  productId: Scalars['ID'];
};

export enum ProductInternalKeyEnum {
  Brand = 'BRAND',
  CurrencyCode = 'CURRENCY_CODE',
  Handle = 'HANDLE',
  ImageSrc = 'IMAGE_SRC',
  InStock = 'IN_STOCK',
  IsActive = 'IS_ACTIVE',
  Price = 'PRICE',
  ProductId = 'PRODUCT_ID',
  ProductUrl = 'PRODUCT_URL',
  PromotionalCode = 'PROMOTIONAL_CODE',
  PromotionalPrice = 'PROMOTIONAL_PRICE',
  PublishedAt = 'PUBLISHED_AT',
  SalePrice = 'SALE_PRICE',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT',
  VariantId = 'VARIANT_ID'
}

export type ProductPickerCollectionType = Node & {
  __typename: 'ProductPickerCollectionType';
  collection: CollectionType;
  handle: Scalars['String'];
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  title: Scalars['String'];
};

export type ProductPriceType = {
  __typename: 'ProductPriceType';
  discountedPrice?: Maybe<Scalars['Float']>;
  discountedPriceRange?: Maybe<PriceRange>;
  discountedPriceRanges: Array<DiscountedPriceRange>;
  discountedPrices: Array<DiscountedPrice>;
  price?: Maybe<Scalars['Float']>;
  priceRange?: Maybe<PriceRange>;
  promotionalPrice?: Maybe<Scalars['Float']>;
  promotionalPriceRange?: Maybe<PriceRange>;
  salePrice?: Maybe<Scalars['Float']>;
  salePriceRange?: Maybe<PriceRange>;
};

export type Program = {
  ambassadorBaseUrl?: Maybe<Scalars['String']>;
  autoApproved: Scalars['Boolean'];
  hideSalesPrice?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  postingRulesUrl?: Maybe<Scalars['String']>;
  programType?: Maybe<ProgramTypeEnum>;
  showOutOfStockProducts?: Maybe<Scalars['Boolean']>;
  signupFormUrl?: Maybe<Scalars['String']>;
};

export type ProgramActivityFilterInput = {
  liveBetween?: InputMaybe<DateTimeFilterInput>;
};

export type ProgramContentRulesInput = {
  badExampleUrl?: InputMaybe<Scalars['String']>;
  goodExampleUrl?: InputMaybe<Scalars['String']>;
  miscRules?: InputMaybe<Scalars['String']>;
  requiredHashtags?: InputMaybe<Array<Scalars['String']>>;
  requiresVerification: Scalars['Boolean'];
  storiesStatusRule: Scalars['String'];
  tagLimit?: InputMaybe<Scalars['Int']>;
  tagType: Scalars['String'];
  whenInDoubt: Scalars['String'];
};

export type ProgramContentRulesType = Node & {
  __typename: 'ProgramContentRulesType';
  badExampleUrl?: Maybe<Scalars['String']>;
  brandPhoto?: Maybe<Scalars['String']>;
  goodExampleUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  miscRules?: Maybe<Scalars['String']>;
  requiredHashtags?: Maybe<Array<Scalars['String']>>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  skipStories: Scalars['Boolean'];
  storiesStatusRule?: Maybe<ProgramRulesStoriesStatusRuleEnum>;
  tagLimit?: Maybe<Scalars['Int']>;
  tagType?: Maybe<ProgramRulesTagTypeRuleEnum>;
  whenInDoubt?: Maybe<MentionStatus>;
};

export type ProgramCustomerEvent = {
  programParticipant?: Maybe<ProgramParticipantType>;
};

export type ProgramFilterInput = {
  search?: InputMaybe<KeywordFilterInput>;
};

export type ProgramHost = {
  allowFbAuth: Scalars['Boolean'];
  customFields?: Maybe<Array<CustomFieldType>>;
  igRequired: Scalars['Boolean'];
  /** @deprecated Use username instead. */
  igUsername?: Maybe<Scalars['String']>;
  lcId: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  program?: Maybe<PublicProgram>;
  socialPlatform?: Maybe<SocialPlatformEnum>;
  socialPlatforms?: Maybe<Array<SocialPlatformEnum>>;
  ttRequired: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ProgramLtvStatusInput = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
  ltvActive: Scalars['Boolean'];
};

export type ProgramMediaType = Node & {
  __typename: 'ProgramMediaType';
  id: Scalars['ID'];
  program: PublicProgram;
  status?: Maybe<MentionStatus>;
};

/** participant fields that anyone can query */
export type ProgramParticipant = {
  affiliateDiscountCodes: Array<EcommDiscountRedeemCodeType>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  shoppableAmbassadorLink?: Maybe<Scalars['String']>;
};

/** participant fields for app users */
export type ProgramParticipantType = ProgramParticipant & {
  __typename: 'ProgramParticipantType';
  affiliateDiscountCodes: Array<EcommDiscountRedeemCodeType>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  customer: CustomerType;
  id: Scalars['Float'];
  program: ProgramType;
  programCommissionTier?: Maybe<CommissionTierType>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  shoppableAmbassadorLink?: Maybe<Scalars['String']>;
  status: ParticipantStatus;
};

export type ProgramPayoutSettingsType = Node & {
  __typename: 'ProgramPayoutSettingsType';
  customDay?: Maybe<Scalars['Int']>;
  day: PaymentDayEnum;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  includeShipping: Scalars['Boolean'];
  includeTax: Scalars['Boolean'];
  orderMaturationPeriodDays: Scalars['Int'];
  paymentIntegration?: Maybe<IntegrationTypes>;
  period: PaymentPeriodEnum;
  tremendousCampaign?: Maybe<TremendousCampaign>;
};

/** Approve all stories */
export enum ProgramRulesStoriesStatusRuleEnum {
  ApproveAll = 'APPROVE_ALL',
  RejectAll = 'REJECT_ALL',
  SameRulesApply = 'SAME_RULES_APPLY'
}

/** Must tag in photo */
export enum ProgramRulesTagTypeRuleEnum {
  Caption = 'CAPTION',
  Tag = 'TAG',
  TagAndCaption = 'TAG_AND_CAPTION',
  TagOrCaption = 'TAG_OR_CAPTION'
}

export enum ProgramSort {
  Name = 'NAME'
}

export enum ProgramTierBuffer {
  FiveDays = 'FIVE_DAYS',
  OneDay = 'ONE_DAY',
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  TwoDays = 'TWO_DAYS'
}

export enum ProgramTierFulfillmentType {
  Instant = 'INSTANT',
  Monthly = 'MONTHLY'
}

export enum ProgramTierGroupStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Paused = 'PAUSED'
}

export type ProgramTierGroupType = Node & {
  __typename: 'ProgramTierGroupType';
  id: Scalars['ID'];
  membersGifted?: Maybe<Scalars['Int']>;
  program?: Maybe<ProgramType>;
  rewardsSent?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProgramTierGroupStatus>;
  tiers?: Maybe<Array<ProgramTierType>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ProgramTierGroupTypeMembersGiftedArgs = {
  where?: InputMaybe<ProgramActivityFilterInput>;
};


export type ProgramTierGroupTypeRewardsSentArgs = {
  where?: InputMaybe<ProgramActivityFilterInput>;
};

export type ProgramTierInput = {
  fulfillmentLimit?: InputMaybe<Scalars['Int']>;
  fulfillmentType: ProgramTierFulfillmentType;
  id?: InputMaybe<Scalars['ID']>;
  mentionTypes?: InputMaybe<Array<MentionTypes>>;
  postTypes?: InputMaybe<Array<IgMediaPostType>>;
  programId?: InputMaybe<Scalars['ID']>;
  rewardId: Scalars['ID'];
  socialAccountIds: Array<Scalars['ID']>;
  triggerBuffer?: InputMaybe<ProgramTierBuffer>;
  triggerQuantity?: InputMaybe<Scalars['Int']>;
};

export enum ProgramTierTriggerType {
  Approved = 'APPROVED',
  CommissionTierChange = 'COMMISSION_TIER_CHANGE',
  Manual = 'MANUAL',
  Mentions = 'MENTIONS',
  Register = 'REGISTER',
  Rejected = 'REJECTED',
  Removed = 'REMOVED'
}

export type ProgramTierType = Node & {
  __typename: 'ProgramTierType';
  fulfillmentLimit?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mentionTypes?: Maybe<Array<MentionTypes>>;
  name?: Maybe<Scalars['String']>;
  postTypes?: Maybe<Array<IgMediaPostType>>;
  program?: Maybe<ProgramType>;
  reward?: Maybe<RewardType>;
  socialAccounts?: Maybe<Array<SocialAccount>>;
  triggerBuffer?: Maybe<ProgramTierBuffer>;
  triggerQuantity?: Maybe<Scalars['Int']>;
  triggerType?: Maybe<ProgramTierTriggerType>;
};

export type ProgramType = Node & Program & {
  __typename: 'ProgramType';
  account?: Maybe<AccountType>;
  ambassadorBaseUrl?: Maybe<Scalars['String']>;
  ambassadorUgcApprovedOnly?: Maybe<Scalars['Boolean']>;
  attributionLandedType: AttributionLandedEnum;
  attributionWindow: Scalars['Int'];
  autoApproved: Scalars['Boolean'];
  campaign?: Maybe<CampaignType>;
  challenges?: Maybe<ChallengeTypePaged>;
  collections?: Maybe<Array<CollectionType>>;
  commissionTiers?: Maybe<Array<CommissionTierType>>;
  commissionsEnabled: Scalars['Boolean'];
  contentRules?: Maybe<ProgramContentRulesType>;
  currencyCode?: Maybe<CurrencyCodeEnum>;
  defaultCommissionTier?: Maybe<CommissionTierType>;
  ecommCustomFields?: Maybe<Array<CustomFieldType>>;
  ecommCustomerSegments?: Maybe<Array<EcommCustomerSegmentType>>;
  ecommDiscountCodes?: Maybe<Array<EcommDiscountCodeType>>;
  ecommIntegration?: Maybe<StorefrontIntegrationTypes>;
  hasAttributedCommissions: Scalars['Boolean'];
  hideSalesPrice?: Maybe<Scalars['Boolean']>;
  hosts?: Maybe<Array<OwnedProgramHost>>;
  id: Scalars['ID'];
  latestProgramActivityStartDate?: Maybe<Scalars['DateTime']>;
  ltvActive: Scalars['Boolean'];
  name: Scalars['String'];
  nextPaymentDate?: Maybe<Scalars['Date']>;
  pendingPosts?: Maybe<Scalars['Int']>;
  postingRulesUrl?: Maybe<Scalars['String']>;
  productPickerCollections?: Maybe<Array<ProductPickerCollectionType>>;
  products?: Maybe<Array<Product>>;
  programPayoutSettings?: Maybe<ProgramPayoutSettingsType>;
  programTierGroups?: Maybe<Array<ProgramTierGroupType>>;
  programType?: Maybe<ProgramTypeEnum>;
  reviewLock?: Maybe<ReviewLockType>;
  showOutOfStockProducts?: Maybe<Scalars['Boolean']>;
  signupFormUrl?: Maybe<Scalars['String']>;
  socialAccounts: Array<SocialAccount>;
  tiers?: Maybe<Array<ProgramTierType>>;
};


export type ProgramTypeChallengesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type ProgramTypeCollectionsArgs = {
  activeFilter?: InputMaybe<Scalars['Boolean']>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type ProgramTypeEcommCustomerSegmentsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type ProgramTypeProductsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type ProgramTypeProgramTierGroupsArgs = {
  where?: InputMaybe<ProgramActivityFilterInput>;
};

export enum ProgramTypeEnum {
  AllCustomer = 'ALL_CUSTOMER',
  Ambassador = 'AMBASSADOR',
  ShoppableAmbassador = 'SHOPPABLE_AMBASSADOR'
}

export type PublicEcommAmbassador = Node & {
  __typename: 'PublicEcommAmbassador';
  affiliateCode?: Maybe<Scalars['String']>;
  ambassadorCollections?: Maybe<Array<AmbassadorCollection>>;
  ambassadorLink?: Maybe<Scalars['String']>;
  ambassadorMedias?: Maybe<Array<AmbassadorMediaType>>;
  collectionProductIds?: Maybe<Array<Scalars['ID']>>;
  customFields?: Maybe<Array<CustomerCustomFieldType>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  linkDiscountEnabled?: Maybe<Scalars['Boolean']>;
  mediaTaggedProducts?: Maybe<Array<Product>>;
  pagedAmbassadorFeedItems: PagedAmbassadorFeedItemType;
  profile?: Maybe<PublicEcommAmbassadorProfile>;
  program: PublicProgram;
  socialUsers?: Maybe<Array<PublicSocialUser>>;
};


export type PublicEcommAmbassadorAmbassadorCollectionsArgs = {
  sharedCollectionId?: InputMaybe<Scalars['ID']>;
};


export type PublicEcommAmbassadorAmbassadorMediasArgs = {
  ambassadorCollectionId?: InputMaybe<Scalars['ID']>;
  currentPagingId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  pagingDirection?: InputMaybe<PagingDirection>;
  sharedMediaId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<Array<AmbassadorMediaSort>>;
  useNewPaging?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AmbassadorFeedMediaFilterInput>;
};


export type PublicEcommAmbassadorCollectionProductIdsArgs = {
  collectionId: Scalars['ID'];
};


export type PublicEcommAmbassadorMediaTaggedProductsArgs = {
  mediaId: Scalars['ID'];
};


export type PublicEcommAmbassadorPagedAmbassadorFeedItemsArgs = {
  ambassadorCollectionId?: InputMaybe<Scalars['ID']>;
  mediaCursor?: InputMaybe<Scalars['String']>;
  productCursor?: InputMaybe<Scalars['String']>;
  sharedMediaId?: InputMaybe<Scalars['ID']>;
  sharedProductId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<AmbassadorFeedSortInput>;
  useNewPaging?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AmbassadorFeedFilterInput>;
};

export type PublicEcommAmbassadorProfile = {
  __typename: 'PublicEcommAmbassadorProfile';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
};

export type PublicIgUser = Node & PublicSocialUser & {
  __typename: 'PublicIGUser';
  avatarUrl?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type PublicProgram = Node & Program & {
  __typename: 'PublicProgram';
  ambassadorBaseUrl?: Maybe<Scalars['String']>;
  autoApproved: Scalars['Boolean'];
  codeDiscount?: Maybe<EcommDiscountCodeType>;
  codelessDiscount?: Maybe<EcommDiscountCodeType>;
  currencyCode?: Maybe<CurrencyCodeEnum>;
  hideSalesPrice?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  postingRulesUrl?: Maybe<Scalars['String']>;
  programPayoutSettings?: Maybe<PublicProgramPayoutSettingsType>;
  programType?: Maybe<ProgramTypeEnum>;
  showOutOfStockProducts?: Maybe<Scalars['Boolean']>;
  signupFormUrl?: Maybe<Scalars['String']>;
};

export type PublicProgramHost = ProgramHost & {
  __typename: 'PublicProgramHost';
  allowFbAuth: Scalars['Boolean'];
  customFields?: Maybe<Array<CustomFieldType>>;
  igRequired: Scalars['Boolean'];
  /** @deprecated Use username instead. */
  igUsername?: Maybe<Scalars['String']>;
  lcId: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  program?: Maybe<PublicProgram>;
  socialPlatform?: Maybe<SocialPlatformEnum>;
  socialPlatforms?: Maybe<Array<SocialPlatformEnum>>;
  ttRequired: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type PublicProgramPayoutSettingsType = Node & {
  __typename: 'PublicProgramPayoutSettingsType';
  customDay?: Maybe<Scalars['Int']>;
  day: PaymentDayEnum;
  id: Scalars['ID'];
  nextPayDate: Scalars['DateTime'];
  orderMaturationPeriodDays?: Maybe<Scalars['Int']>;
  payPeriodRange: DateTimeRange;
  period: PaymentPeriodEnum;
};

export type PublicSocialUser = {
  avatarUrl?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type PublicTtUser = Node & PublicSocialUser & {
  __typename: 'PublicTTUser';
  avatarUrl?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  account?: Maybe<AccountType>;
  accounts?: Maybe<PagedAccountType>;
  ambassadorHub?: Maybe<AmbassadorHub>;
  appStripeProducts?: Maybe<Array<StripeProduct>>;
  campaign?: Maybe<CampaignType>;
  challenge?: Maybe<ChallengeType>;
  challenges?: Maybe<ChallengeTypePaged>;
  customerSourceJob?: Maybe<CustomerSourceJob>;
  ecommAmbassador?: Maybe<PublicEcommAmbassador>;
  ecommStore?: Maybe<EcommStore>;
  igMedia?: Maybe<IgMedia>;
  labels?: Maybe<Array<Maybe<LabelType>>>;
  me?: Maybe<Identity>;
  mention?: Maybe<Mention>;
  mentions?: Maybe<PagedMentionType>;
  mockEcommAmbassador?: Maybe<MockEcommAmbassador>;
  noteCategories?: Maybe<Array<CustomerNoteCategory>>;
  product: Product;
  productByUrl?: Maybe<Product>;
  program?: Maybe<ProgramType>;
  programHost?: Maybe<PublicProgramHost>;
  programs: PagedProgramType;
  segments?: Maybe<Array<Maybe<SegmentType>>>;
  socialAccount?: Maybe<SocialAccount>;
  socialAccounts?: Maybe<Array<SocialAccount>>;
  socialAccountsPaged?: Maybe<PagedSocialAccountType>;
  whoami?: Maybe<UserType>;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<AccountSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<AccountFilterInput>;
};


export type QueryCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryChallengeArgs = {
  id: Scalars['ID'];
};


export type QueryChallengesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  forReview?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerSourceJobArgs = {
  id: Scalars['ID'];
};


export type QueryEcommAmbassadorArgs = {
  id: Scalars['ID'];
  shopId?: InputMaybe<Scalars['ID']>;
  shopUrl?: InputMaybe<Scalars['String']>;
  shopifyId?: InputMaybe<Scalars['String']>;
};


export type QueryEcommStoreArgs = {
  id?: InputMaybe<Scalars['ID']>;
  shopifyId?: InputMaybe<Scalars['String']>;
};


export type QueryIgMediaArgs = {
  id: Scalars['ID'];
};


export type QueryLabelsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryMentionArgs = {
  id: Scalars['ID'];
};


export type QueryMentionsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  socialAccountId: Scalars['ID'];
  sortBy?: InputMaybe<MentionSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<MentionFilterInput>;
};


export type QueryMockEcommAmbassadorArgs = {
  shopId?: InputMaybe<Scalars['ID']>;
  shopUrl?: InputMaybe<Scalars['String']>;
  shopifyId?: InputMaybe<Scalars['String']>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductByUrlArgs = {
  shopId?: InputMaybe<Scalars['ID']>;
  shopifyId?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};


export type QueryProgramArgs = {
  id: Scalars['ID'];
};


export type QueryProgramHostArgs = {
  lcId: Scalars['ID'];
};


export type QueryProgramsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProgramSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<ProgramFilterInput>;
};


export type QuerySegmentsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySocialAccountArgs = {
  id: Scalars['ID'];
};


export type QuerySocialAccountsPagedArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  platform: SocialPlatformEnum;
  sortBy?: InputMaybe<SocialAccountSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<SocialAccountFilterInput>;
};

export type RefersionIntegration = Integration & Node & {
  __typename: 'RefersionIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  igUsernameFields: Array<Scalars['String']>;
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
  ttUsernameFields: Array<Scalars['String']>;
};

export type RefreshFbUser = {
  __typename: 'RefreshFBUser';
  fbUser?: Maybe<FbUser>;
  ok: Scalars['Boolean'];
};

export type RefundColumnMapperInput = {
  fileColumn: Scalars['String'];
  internalKey: RefundInternalKeyEnum;
};

export enum RefundInternalKeyEnum {
  Amount = 'AMOUNT',
  CurrencyCode = 'CURRENCY_CODE',
  LineItemId = 'LINE_ITEM_ID',
  OrderId = 'ORDER_ID',
  ProductId = 'PRODUCT_ID',
  Quantity = 'QUANTITY',
  RefundedAt = 'REFUNDED_AT',
  RefundId = 'REFUND_ID'
}

export type RegisterCampaignParticipant = {
  __typename: 'RegisterCampaignParticipant';
  ok?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<RegisteredParticipant>>;
};

export type RegisterProgramCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & ProgramCustomerEvent & {
  __typename: 'RegisterProgramCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  programParticipant?: Maybe<ProgramParticipantType>;
};

export type RegisteredParticipant = {
  __typename: 'RegisteredParticipant';
  email?: Maybe<Scalars['String']>;
  igUsername?: Maybe<Scalars['String']>;
  storefrontUrl?: Maybe<Scalars['String']>;
  ttUsername?: Maybe<Scalars['String']>;
};

export type RegistrationInput = {
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  email?: InputMaybe<Scalars['String']>;
  fbToken?: InputMaybe<Scalars['String']>;
  igUsername?: InputMaybe<Scalars['String']>;
  ttUsername?: InputMaybe<Scalars['String']>;
};

/** Date ranges relative to now. past dates only */
export type RelativeDateRangeFilter = DateRangeFilterBase & {
  __typename: 'RelativeDateRangeFilter';
  isExclude?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  rangeType: DateRangeFilterType;
  unit: DateRangeFilterUnits;
  value: Scalars['Int'];
};

export type RemoveCustomerNote = {
  __typename: 'RemoveCustomerNote';
  note?: Maybe<CustomerNoteType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveCustomerNoteEvent = ActorCustomerEvent & BaseCustomerEvent & CustomerNoteEvent & {
  __typename: 'RemoveCustomerNoteEvent';
  actor?: Maybe<UserType>;
  customerNote: CustomerNoteType;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type RemoveCustomerSegment = {
  __typename: 'RemoveCustomerSegment';
  customers?: Maybe<Array<CustomerType>>;
  ok?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<SegmentType>;
};

export type RemoveLabelCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & LabelCustomerEvent & {
  __typename: 'RemoveLabelCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  label: LabelType;
  mention: Mention;
};

export type RemoveMentionLabel = {
  __typename: 'RemoveMentionLabel';
  label?: Maybe<LabelType>;
  mentions?: Maybe<Array<Mention>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveParticipant = {
  __typename: 'RemoveParticipant';
  ok?: Maybe<Scalars['Boolean']>;
  participant?: Maybe<ProgramParticipantType>;
};

export type RemoveSegmentCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & SegmentCustomerEvent & {
  __typename: 'RemoveSegmentCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  segment: SegmentType;
};

export type ResendUserEmail = {
  __typename: 'ResendUserEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ResendUserInvite = {
  __typename: 'ResendUserInvite';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ReviewLockType = Node & {
  __typename: 'ReviewLockType';
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserType>;
};

export type RewardDiscountCodeType = Node & {
  __typename: 'RewardDiscountCodeType';
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type RewardFilterInput = {
  campaigns?: InputMaybe<CampaignsFilterInput>;
  challengeIds?: InputMaybe<ChallengesFilterInput>;
  integrations?: InputMaybe<IntegrationsFilterInput>;
  rewardType?: InputMaybe<RewardTypeFilterInput>;
  search?: InputMaybe<KeywordFilterInput>;
};

export enum RewardSort {
  Name = 'NAME'
}

/**
 * Stats for sent rewards and discounts.
 * what is 'current batch' when referring to discount codes?
 *     - all codes that were most recently uploaded together + any unused codes from previous batches
 *     - uploaded together means uploaded within an hour of each other
 */
export type RewardStats = {
  __typename: 'RewardStats';
  codesInCurrentBatch?: Maybe<Scalars['Int']>;
  codesLevel?: Maybe<CodesLevel>;
  codesRemaining?: Maybe<Scalars['Int']>;
  codesTotal?: Maybe<Scalars['Int']>;
  codesUsed?: Maybe<Scalars['Int']>;
  lastBatchAt?: Maybe<Scalars['DateTime']>;
  sentTotal: Scalars['Int'];
};

export type RewardStatsInput = {
  rewardedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RewardType = {
  __typename: 'RewardType';
  account?: Maybe<AccountType>;
  bluecoreCampaignId?: Maybe<Scalars['String']>;
  challenges?: Maybe<Array<ChallengeType>>;
  currencyCode?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<Scalars['String']>;
  emailTemplateName?: Maybe<Scalars['String']>;
  emailTemplateVariableName?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  hasDiscountCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  integration?: Maybe<Integration>;
  isDmReward: Scalars['Boolean'];
  messageTemplates?: Maybe<Array<Maybe<MessageTemplate>>>;
  name?: Maybe<Scalars['String']>;
  stats: RewardStats;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  tierId?: Maybe<Scalars['String']>;
  tiers?: Maybe<Array<ProgramTierType>>;
  tremendousCampaignId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};


export type RewardTypeStatsArgs = {
  where?: InputMaybe<RewardStatsInput>;
};

export enum RewardTypeEnum {
  Dm = 'DM',
  Email = 'EMAIL',
  Integration = 'INTEGRATION',
  Manual = 'MANUAL',
  Sms = 'SMS'
}

export type RewardTypeFilterInput = {
  any: Array<RewardTypeEnum>;
};

export type RoleType = Node & {
  __typename: 'RoleType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SftpIntegration = Integration & Node & {
  __typename: 'SFTPIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  mappings: Array<ColumnMapping>;
  name: Scalars['String'];
};

export type SftpPullIntegration = Integration & Node & {
  __typename: 'SFTPPullIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  filePath: Scalars['String'];
  id: Scalars['ID'];
  integrationType: IntegrationType;
  mappings: Array<ColumnMapping>;
  name: Scalars['String'];
  objectType: ClientSftpObjectType;
  publicKey?: Maybe<Scalars['String']>;
  serverUrl: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type SalesforceOcapiIntegration = Integration & Node & {
  __typename: 'SalesforceOcapiIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  clientId: Scalars['String'];
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  locale: Scalars['String'];
  mappings: Array<ColumnMapping>;
  name: Scalars['String'];
  shopUrl: Scalars['String'];
  siteId: Scalars['String'];
};

export type SavedFilterInput = {
  approvedAt?: InputMaybe<DateTimeRangeFilterInput>;
  avgEngagementRate?: InputMaybe<IntegerRangeFilterInput>;
  biography?: InputMaybe<BiographyFilterInput>;
  campaignId?: InputMaybe<Scalars['ID']>;
  campaigns?: InputMaybe<CampaignsFilterInput>;
  caption?: InputMaybe<KeywordFilterInput>;
  challengeIds?: InputMaybe<ChallengesFilterInput>;
  challengeMediaApproval?: InputMaybe<TagStatusFilterInput>;
  commissionRate?: InputMaybe<FloatRangeFilterInput>;
  commissionTiers?: InputMaybe<IdFilterInput>;
  commissionsEarned?: InputMaybe<IntegerRangeFilterInput>;
  conversionRate?: InputMaybe<IntegerRangeFilterInput>;
  customFields?: InputMaybe<CustomFieldsSavedFilterInput>;
  dateRange?: InputMaybe<DateTimeRangeFilterInput>;
  email?: InputMaybe<KeywordFilterInput>;
  engagementRate?: InputMaybe<IntegerRangeFilterInput>;
  expiredStories?: InputMaybe<Scalars['Boolean']>;
  followerCount?: InputMaybe<IntegerRangeFilterInput>;
  hashtag?: InputMaybe<KeywordFilterInput>;
  impressions?: InputMaybe<IntegerRangeFilterInput>;
  includePremembership?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<LabelsFilterInput>;
  linkViews?: InputMaybe<IntegerRangeFilterInput>;
  maxPostedAt?: InputMaybe<DateTimeRangeFilterInput>;
  maxRewardedAt?: InputMaybe<DateTimeRangeFilterInput>;
  mediaType?: InputMaybe<MediaTypeFilterInput>;
  messageDate?: InputMaybe<DateInclusionExclusionFilterInput>;
  messageKeywords?: InputMaybe<KeywordsFilterInput>;
  messageSendingMethod?: InputMaybe<MessageSendingMethodsFilterInput>;
  messageTemplate?: InputMaybe<IdFilterInput>;
  minPostedAt?: InputMaybe<DateTimeRangeFilterInput>;
  noteCategories?: InputMaybe<IdFilterInput>;
  noteCreatedAt?: InputMaybe<DateTimeRangeFilterInput>;
  notes?: InputMaybe<KeywordFilterInput>;
  paymentPeriods?: InputMaybe<IdFilterInput>;
  postCount?: InputMaybe<IntegerRangeFilterInput>;
  postType?: InputMaybe<PostTypeFilterInput>;
  postedAt?: InputMaybe<DateTimeRangeFilterInput>;
  referredOrders?: InputMaybe<IntegerRangeFilterInput>;
  referredSalesRevenue?: InputMaybe<IntegerRangeFilterInput>;
  rewards?: InputMaybe<IdFilterInput>;
  segments?: InputMaybe<SegmentsFilterInput>;
  signedUpAt?: InputMaybe<DateTimeRangeFilterInput>;
  tagStatus?: InputMaybe<TagStatusFilterInput>;
  unavailableMedia?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<KeywordFilterInput>;
  wasCaptionMentioned?: InputMaybe<Scalars['Boolean']>;
  wasHashtagMentioned?: InputMaybe<Scalars['Boolean']>;
  wasStoryMentioned?: InputMaybe<Scalars['Boolean']>;
  wasTagged?: InputMaybe<Scalars['Boolean']>;
};

export type SegmentCampaignFilterInput = {
  all: Array<AnySegmentCampaignFilterInput>;
};

export type SegmentCustomerEvent = {
  segment: SegmentType;
};

export type SegmentFilterInput = {
  search?: InputMaybe<KeywordFilterInput>;
};

export type SegmentLimit = {
  __typename: 'SegmentLimit';
  hasHitLimit: Scalars['Boolean'];
  isUnlimited: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  used: Scalars['Int'];
};

export enum SegmentSort {
  Name = 'NAME',
  RecentlyUsed = 'RECENTLY_USED'
}

export type SegmentType = {
  __typename: 'SegmentType';
  account?: Maybe<AccountType>;
  customerCount?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type SegmentsFilterInput = {
  all?: InputMaybe<Array<Array<Scalars['ID']>>>;
  any?: InputMaybe<Array<Scalars['ID']>>;
  none?: InputMaybe<Array<Scalars['ID']>>;
};

export type SendDirectMessage = {
  __typename: 'SendDirectMessage';
  message?: Maybe<Message>;
  ok: Scalars['Boolean'];
};

export type SendFulfillment = {
  __typename: 'SendFulfillment';
  fulfillments?: Maybe<Array<FulfillmentType>>;
  ok: Scalars['Boolean'];
};

export type SendMonthlyEmail = {
  __typename: 'SendMonthlyEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendinblueIntegration = Integration & Node & {
  __typename: 'SendinblueIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  fromEmail: Scalars['String'];
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type SentMessageCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & {
  __typename: 'SentMessageCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Message;
};

export type SentMessageStats = {
  __typename: 'SentMessageStats';
  emv: Scalars['Float'];
  recipients: Scalars['Int'];
  rewardsSent: Scalars['Int'];
  total: Scalars['Int'];
};

export type SetCampaignCommissionsEnabled = {
  __typename: 'SetCampaignCommissionsEnabled';
  ok: Scalars['Boolean'];
};

export type SetCommissionTierCustomerEvent = ActorCustomerEvent & BaseCustomerEvent & ProgramCustomerEvent & {
  __typename: 'SetCommissionTierCustomerEvent';
  actor?: Maybe<UserType>;
  eventAt: Scalars['DateTime'];
  id: Scalars['ID'];
  newCommissionTier: CommissionTierType;
  oldCommissionTier: CommissionTierType;
  programParticipant?: Maybe<ProgramParticipantType>;
};

export type SetParticipantCommissionTier = {
  __typename: 'SetParticipantCommissionTier';
  affectedRows: Scalars['Int'];
  ok: Scalars['Boolean'];
};

export type ShopifyIntegration = EcommStore & Integration & Node & {
  __typename: 'ShopifyIntegration';
  brandMessage?: Maybe<Scalars['String']>;
  capabilities: Array<IntegrationCapabilityEnum>;
  creatorWidget: Scalars['Boolean'];
  creatorWidgetPosition: EcommWidgetPosition;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  displayName?: Maybe<Scalars['String']>;
  features: Features;
  hideCommissionsWithPending?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ilpFilterSegment?: Maybe<SegmentType>;
  integrationType: IntegrationType;
  keepUnattributedOrders?: Maybe<Scalars['Boolean']>;
  lcRelativeLogoUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  otpLoginUrl?: Maybe<Scalars['String']>;
  pagedEcommStoreAmbassadors?: Maybe<PagedPublicEcommAmbassador>;
  pagedProductPickerCollectionProducts?: Maybe<PagedProductPickerCollectionType>;
  productPickerCollections?: Maybe<Array<ProductPickerCollectionType>>;
  relationship?: Maybe<StoreAmbassadorRelationship>;
  relationshipLegacy?: Maybe<AmbassadorHub>;
  sellsSubscriptions?: Maybe<Scalars['Boolean']>;
  shopCode: Scalars['String'];
  shopName: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  storeLogoUrl?: Maybe<Scalars['String']>;
};


export type ShopifyIntegrationPagedEcommStoreAmbassadorsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
};


export type ShopifyIntegrationPagedProductPickerCollectionProductsArgs = {
  pagedDataInput?: InputMaybe<PagedDataInput>;
  productPickerCollectionId?: InputMaybe<Scalars['ID']>;
};

export type ShopifyQueryParamsInput = {
  code: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  shop: Scalars['String'];
  state: Scalars['String'];
  timestamp: Scalars['String'];
};

export type SignupAccount = {
  __typename: 'SignupAccount';
  account?: Maybe<AccountType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export enum SkipMessageTemplateActionEnum {
  AnyTemplate = 'ANY_TEMPLATE',
  Never = 'NEVER',
  SameTemplate = 'SAME_TEMPLATE'
}

export type SmileIntegration = Integration & Node & {
  __typename: 'SmileIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type SocialAccount = {
  campaigns?: Maybe<Array<CampaignType>>;
  challenges?: Maybe<ChallengeTypePaged>;
  customer?: Maybe<CustomerType>;
  customers?: Maybe<PagedCustomerType>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fbUsers?: Maybe<Array<FbUser>>;
  hashtags: PagedHashtags;
  id: Scalars['ID'];
  invalidAt?: Maybe<Scalars['DateTime']>;
  lastPull?: Maybe<Scalars['DateTime']>;
  mentionStatsTimeseries?: Maybe<Array<SocialDataPoint>>;
  platformId?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<ProgramType>>;
  socialUser: SocialUser;
  stats?: Maybe<StatsType>;
  syncProgress?: Maybe<SyncProgressType>;
};


export type SocialAccountChallengesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type SocialAccountCustomerArgs = {
  id: Scalars['ID'];
};


export type SocialAccountCustomersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CustomerSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type SocialAccountHashtagsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<HashtagMentionsFilter>;
};


export type SocialAccountMentionStatsTimeseriesArgs = {
  timeDimension: TimeDimension;
  timezone: Scalars['String'];
  where: MentionFilterInput;
};


export type SocialAccountStatsArgs = {
  where: MentionFilterInput;
};

export type SocialAccountFilterInput = {
  socialAccountUsername?: InputMaybe<KeywordFilterInput>;
};

export type SocialAccountHashtagType = Node & {
  __typename: 'SocialAccountHashtagType';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  hashtag?: Maybe<Scalars['String']>;
  hashtagId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SocialAccountLimit = {
  __typename: 'SocialAccountLimit';
  hasHitLimit: Scalars['Boolean'];
  isUnlimited: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  used: Scalars['Int'];
};

export enum SocialAccountSort {
  CreatedAt = 'CREATED_AT'
}

export type SocialDataPoint = MentionStats & {
  __typename: 'SocialDataPoint';
  emv: Scalars['Float'];
  endDate: Scalars['Date'];
  engagement: Scalars['Float'];
  impressions: Scalars['Float'];
  posts: Scalars['Int'];
  startDate: Scalars['Date'];
  ugcValue: Scalars['Float'];
};

export enum SocialPlatformEnum {
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK'
}

/** Brand accessible social user data  */
export type SocialUser = {
  avatarUrl?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerType>;
  externalUrl?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  username: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StatsType = MentionStats & {
  __typename: 'StatsType';
  emv: Scalars['Float'];
  engagement: Scalars['Float'];
  impressions: Scalars['Float'];
  posts: Scalars['Int'];
  ugcValue: Scalars['Float'];
};

export type StoreAmbassadorRelationship = {
  __typename: 'StoreAmbassadorRelationship';
  ambassadorStats?: Maybe<AmbassadorStats>;
  availableChallengeRewards?: Maybe<Array<AvailableReward>>;
  availableProgramTierRewards?: Maybe<Array<AvailableReward>>;
  earnedChallengeRewards?: Maybe<Array<EarnedReward>>;
  earnedDmRewards?: Maybe<Array<EarnedReward>>;
  earnedNonProgramRewards?: Maybe<Array<EarnedReward>>;
  earnedProgramTierRewards?: Maybe<Array<EarnedReward>>;
  hiddenIgReelsCount?: Maybe<Scalars['Int']>;
  hiddenIgStoriesCount?: Maybe<Scalars['Int']>;
  hiddenTtFeedCount?: Maybe<Scalars['Int']>;
  igUser?: Maybe<PublicSocialUser>;
  invisibleUgcPosts?: Maybe<Array<InvisibleMedia>>;
  participants: Array<AmbassadorHubProgramParticipant>;
  shoppableParticipants?: Maybe<Array<AmbassadorHubProgramParticipant>>;
  socialStats?: Maybe<AmbassadorHubSocialStats>;
  store: EcommStore;
  storefrontParticipant?: Maybe<AmbassadorHubProgramParticipant>;
  ttUser?: Maybe<PublicSocialUser>;
  visibleUgcPosts?: Maybe<Array<VisibleMedia>>;
};


export type StoreAmbassadorRelationshipAmbassadorStatsArgs = {
  where?: InputMaybe<AmbassadorStatsInput>;
};

export enum StorefrontFeedItemTypeEnum {
  Media = 'MEDIA',
  Product = 'PRODUCT'
}

export type StorefrontIntegrationTypes = CommerceCloudIntegration | CustomStoreIntegration | ShopifyIntegration;

export enum StorefrontLayoutEnum {
  Bio = 'BIO'
}

export type StripeProduct = Node & {
  __typename: 'StripeProduct';
  appProducts: Array<AccountProductName>;
  campaignLimit?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Use social_account_limit. */
  igAccountLimit?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  isEnterprise: Scalars['Boolean'];
  /** is a package for our app */
  isInApp: Scalars['Boolean'];
  isMessagingStoryReplies: Scalars['Boolean'];
  /** is a package made for self service. (not enterprise) */
  isSelfService: Scalars['Boolean'];
  isTiktok: Scalars['Boolean'];
  isTrialEligible: Scalars['Boolean'];
  labelLimit?: Maybe<Scalars['Int']>;
  monthlyPrice?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  postLimit?: Maybe<Scalars['Int']>;
  /** stripe id for product */
  productId: Scalars['String'];
  segmentLimit?: Maybe<Scalars['Int']>;
  socialAccountLimit?: Maybe<Scalars['Int']>;
  userLimit?: Maybe<Scalars['Int']>;
  yearlyPrice?: Maybe<Scalars['Float']>;
};

export type SyncIntegrationCollections = {
  __typename: 'SyncIntegrationCollections';
  integration?: Maybe<IntegrationTypes>;
  ok: Scalars['Boolean'];
};

export type SyncIntegrationProducts = {
  __typename: 'SyncIntegrationProducts';
  integration?: Maybe<IntegrationTypes>;
  ok: Scalars['Boolean'];
};

export type SyncProgressType = {
  __typename: 'SyncProgressType';
  initialIgUsers?: Maybe<Scalars['Int']>;
  isInitialJobPending: Scalars['Boolean'];
  pendingIgUsers?: Maybe<Scalars['Int']>;
};

export type SyncSubscriptions = {
  __typename: 'SyncSubscriptions';
  ok?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
};

export type TtMedia = Media & Node & {
  __typename: 'TTMedia';
  caption?: Maybe<Scalars['String']>;
  challengeMedia?: Maybe<Array<Maybe<ChallengeMediaType>>>;
  children?: Maybe<Array<TtMediaChild>>;
  hashtags?: Maybe<Array<MediaHashtag>>;
  id: Scalars['ID'];
  lcMediaUrl?: Maybe<Scalars['String']>;
  lcThumbnailUrl?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  postedAt: Scalars['DateTime'];
  poster: TtUser;
  programMedia?: Maybe<Array<ProgramMediaType>>;
  stats?: Maybe<TtMediaStats>;
};

export type TtMediaChild = {
  __typename: 'TTMediaChild';
  id: Scalars['ID'];
  lcMediaUrl?: Maybe<Scalars['String']>;
  lcThumbnailUrl?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type TtMediaStats = MediaStats & Node & {
  __typename: 'TTMediaStats';
  commentCount?: Maybe<Scalars['Int']>;
  emv: Scalars['Float'];
  engagementRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  impressions?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  replies?: Maybe<Scalars['Int']>;
  ugcValue: Scalars['Float'];
};

export type TtMention = Mention & Node & {
  __typename: 'TTMention';
  id: Scalars['ID'];
  isPastLimit: Scalars['Boolean'];
  labels?: Maybe<Array<LabelType>>;
  media: TtMedia;
  socialAccount: TtSocialAccount;
  status: MentionStatus;
  wasCaptionMentioned: Scalars['Boolean'];
  wasDirectMessaged?: Maybe<Scalars['Boolean']>;
  wasHashtagMentioned: Scalars['Boolean'];
  wasRewarded?: Maybe<Scalars['Boolean']>;
  wasStoryMentioned: Scalars['Boolean'];
  wasTagged: Scalars['Boolean'];
};

export type TtSocialAccount = Node & SocialAccount & {
  __typename: 'TTSocialAccount';
  account: AccountType;
  activeTrackedHashtag?: Maybe<Scalars['String']>;
  campaigns?: Maybe<Array<CampaignType>>;
  challenges?: Maybe<ChallengeTypePaged>;
  customer?: Maybe<CustomerType>;
  customers?: Maybe<PagedCustomerType>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fbUsers?: Maybe<Array<FbUser>>;
  hashtags: PagedHashtags;
  id: Scalars['ID'];
  invalidAt?: Maybe<Scalars['DateTime']>;
  lastHashtagUpdatedAt?: Maybe<Scalars['DateTime']>;
  lastPull?: Maybe<Scalars['DateTime']>;
  mentionStatsTimeseries?: Maybe<Array<SocialDataPoint>>;
  platformId?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<ProgramType>>;
  socialUser: TtUser;
  stats?: Maybe<StatsType>;
  syncProgress?: Maybe<SyncProgressType>;
  trackedHashtags?: Maybe<Array<SocialAccountHashtagType>>;
};


export type TtSocialAccountChallengesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type TtSocialAccountCustomerArgs = {
  id: Scalars['ID'];
};


export type TtSocialAccountCustomersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CustomerSort>;
  sortDirection?: InputMaybe<SortDirection>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type TtSocialAccountHashtagsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionsWhere?: InputMaybe<HashtagMentionsFilter>;
};


export type TtSocialAccountMentionStatsTimeseriesArgs = {
  timeDimension: TimeDimension;
  timezone: Scalars['String'];
  where: MentionFilterInput;
};


export type TtSocialAccountStatsArgs = {
  where: MentionFilterInput;
};

export type TtUser = Node & SocialUser & {
  __typename: 'TTUser';
  avatarUrl?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerType>;
  externalUrl?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ttSecUid?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type TagStatusFilter = {
  __typename: 'TagStatusFilter';
  any?: Maybe<Array<MentionStatus>>;
};

export type TagStatusFilterInput = {
  any: Array<MentionStatus>;
};

export type TestFulfillment = {
  __typename: 'TestFulfillment';
  ok: Scalars['Boolean'];
  result?: Maybe<Scalars['JSONString']>;
};

export type TestFulfillmentInput = {
  campaignId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  deliveryMethod?: InputMaybe<Scalars['String']>;
  discountCode?: InputMaybe<Scalars['String']>;
  discountCodeFieldName?: InputMaybe<Scalars['String']>;
  emailTemplateName?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  fulfillmentAccountId: Scalars['ID'];
  igUsername?: InputMaybe<Scalars['String']>;
  loyaltyTierId?: InputMaybe<Scalars['String']>;
  recipientName?: InputMaybe<Scalars['String']>;
  recipientPhone?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  toEmail: Scalars['String'];
  tremendousCampaignId?: InputMaybe<Scalars['String']>;
  ttUsername?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type TestIntegration = {
  __typename: 'TestIntegration';
  ok: Scalars['Boolean'];
};

export enum TimeDimension {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type ToggleAmbassadorCollectionFeatureMedia = {
  __typename: 'ToggleAmbassadorCollectionFeatureMedia';
  isFeatured: Scalars['Boolean'];
  ok: Scalars['Boolean'];
};

export type ToggleAmbassadorCollectionFeatureProduct = {
  __typename: 'ToggleAmbassadorCollectionFeatureProduct';
  ok: Scalars['Boolean'];
};

export type ToggleAmbassadorMediaVisible = {
  __typename: 'ToggleAmbassadorMediaVisible';
  hidden: Scalars['Boolean'];
  ok: Scalars['Boolean'];
};

export type TogglePauseChallenge = {
  __typename: 'TogglePauseChallenge';
  challenge?: Maybe<ChallengeType>;
  ok: Scalars['Boolean'];
};

/** Start tracking social accounts for a fb user, (saves social account) */
export type TrackSocialAccounts = {
  __typename: 'TrackSocialAccounts';
  ok: Scalars['Boolean'];
  socialAccounts?: Maybe<Array<IgSocialAccount>>;
};

/** Tremendous campaign data for AddEditReward dropdown */
export type TremendousCampaign = {
  __typename: 'TremendousCampaign';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type TremendousIntegration = Integration & Node & {
  __typename: 'TremendousIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  fundingSourceId: Scalars['String'];
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
  organizationName: Scalars['String'];
  tremendousCampaigns: Array<TremendousCampaign>;
};

export enum TriggerType {
  AmbassadorAttribution = 'AMBASSADOR_ATTRIBUTION',
  Mentioned = 'MENTIONED'
}

export type TriggerTypes = MentionedTrigger;

export type UpdateAutomation = {
  __typename: 'UpdateAutomation';
  automation?: Maybe<Automation>;
  ok: Scalars['Boolean'];
};

export type UpdateCampaignSettings = {
  __typename: 'UpdateCampaignSettings';
  campaign?: Maybe<CampaignType>;
  ok: Scalars['Boolean'];
};

export type UpdateChallenge = {
  __typename: 'UpdateChallenge';
  challenge?: Maybe<ChallengeType>;
  ok: Scalars['Boolean'];
};

export type UpdateChallengeMediaApproval = {
  __typename: 'UpdateChallengeMediaApproval';
  challenge?: Maybe<ChallengeType>;
  ok: Scalars['Boolean'];
};

export type UpdateCustomer = {
  __typename: 'UpdateCustomer';
  customer?: Maybe<CustomerType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateCustomerNote = {
  __typename: 'UpdateCustomerNote';
  note?: Maybe<CustomerNoteType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateFulfillmentAccount = {
  __typename: 'UpdateFulfillmentAccount';
  fulfillmentAccount?: Maybe<FulfillAccountType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateIntegration = {
  __typename: 'UpdateIntegration';
  integration?: Maybe<IntegrationTypes>;
  ok: Scalars['Boolean'];
};

export type UpdateLabel = {
  __typename: 'UpdateLabel';
  label?: Maybe<LabelType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateMentionStatus = {
  __typename: 'UpdateMentionStatus';
  mention?: Maybe<Mention>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateMessageTemplate = {
  __typename: 'UpdateMessageTemplate';
  messageTemplate?: Maybe<MessageTemplate>;
  ok: Scalars['Boolean'];
};

export type UpdateParticipantStatus = {
  __typename: 'UpdateParticipantStatus';
  ok?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<ProgramParticipantType>>;
};

export type UpdateProgramContentRules = {
  __typename: 'UpdateProgramContentRules';
  contentRules?: Maybe<ProgramContentRulesType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateProgramsLtvStatuses = {
  __typename: 'UpdateProgramsLtvStatuses';
  ok: Scalars['Boolean'];
  updatedPrograms?: Maybe<Array<ProgramType>>;
};

export type UpdateReward = {
  __typename: 'UpdateReward';
  ok?: Maybe<Scalars['Boolean']>;
  reward?: Maybe<RewardType>;
};

export type UpdateRewardInputType = {
  bluecoreCampaignId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  deliveryMethod?: InputMaybe<Scalars['String']>;
  emailTemplateName?: InputMaybe<Scalars['String']>;
  emailTemplateVariableName?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  hasDiscountCode?: InputMaybe<Scalars['Boolean']>;
  integrationId?: InputMaybe<Scalars['ID']>;
  isDmReward?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  tierId?: InputMaybe<Scalars['String']>;
  tremendousCampaignId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type UpdateSegment = {
  __typename: 'UpdateSegment';
  ok?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<SegmentType>;
};

/** Update IGSocialAccounts only */
export type UpdateSocialAccounts = {
  __typename: 'UpdateSocialAccounts';
  allSocialAccounts?: Maybe<Array<IgSocialAccount>>;
  ok: Scalars['Boolean'];
};

export type UpdateSocialUsername = {
  __typename: 'UpdateSocialUsername';
  customer?: Maybe<CustomerType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateTikTokSocialAccount = {
  __typename: 'UpdateTikTokSocialAccount';
  ok: Scalars['Boolean'];
  ttSocialAccount?: Maybe<TtSocialAccount>;
};

export type UpdateTikTokSocialAccountHashtag = {
  __typename: 'UpdateTikTokSocialAccountHashtag';
  ok: Scalars['Boolean'];
  trackedHashtag?: Maybe<SocialAccountHashtagType>;
};

export type UpdateUser = {
  __typename: 'UpdateUser';
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export type UpsertAmbassadorCollection = {
  __typename: 'UpsertAmbassadorCollection';
  ambassadorCollection?: Maybe<AmbassadorCollection>;
};

export type UpsertAmbassadorCollectionMediaProducts = {
  __typename: 'UpsertAmbassadorCollectionMediaProducts';
  ok: Scalars['Boolean'];
};

export type UpsertAmbassadorCollectionProducts = {
  __typename: 'UpsertAmbassadorCollectionProducts';
  ok?: Maybe<Scalars['Boolean']>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type UpsertAmbassadorProfile = {
  __typename: 'UpsertAmbassadorProfile';
  ok: Scalars['Boolean'];
};

export type UpsertCommissionTier = {
  __typename: 'UpsertCommissionTier';
  commissionTier?: Maybe<CommissionTierType>;
  ok: Scalars['Boolean'];
};

export type UpsertProductToAmbassadorCollections = {
  __typename: 'UpsertProductToAmbassadorCollections';
  ok: Scalars['Boolean'];
};

export type UpsertProgramEcommDiscountCode = {
  __typename: 'UpsertProgramEcommDiscountCode';
  ecommDiscountCode?: Maybe<EcommDiscountCodeType>;
  ok: Scalars['Boolean'];
};

export type UpsertProgramParticipantEcommDiscountRedeemCode = {
  __typename: 'UpsertProgramParticipantEcommDiscountRedeemCode';
  ecommDiscountRedeemCode?: Maybe<EcommDiscountRedeemCodeType>;
  ok: Scalars['Boolean'];
};

export type UpsertProgramTierGroup = {
  __typename: 'UpsertProgramTierGroup';
  ok?: Maybe<Scalars['Boolean']>;
  programTierGroup?: Maybe<ProgramTierGroupType>;
};

export type UserLimit = {
  __typename: 'UserLimit';
  hasHitLimit: Scalars['Boolean'];
  isUnlimited: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  used: Scalars['Int'];
};

export enum UserRoleType {
  Admin = 'ADMIN',
  BetaTester = 'BETA_TESTER',
  Main = 'MAIN',
  Owner = 'OWNER',
  Reviewer = 'REVIEWER'
}

export type UserType = {
  __typename: 'UserType';
  account?: Maybe<AccountType>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['Float'];
  isFbTester: Scalars['Boolean'];
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<RoleType>>;
  title?: Maybe<Scalars['String']>;
};

export type ValidateAndSaveFbUser = {
  __typename: 'ValidateAndSaveFbUser';
  email?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  usernames?: Maybe<Array<Scalars['String']>>;
};

export type VisibleMedia = {
  __typename: 'VisibleMedia';
  caption?: Maybe<Scalars['String']>;
  children?: Maybe<Array<VisibleMediaChild>>;
  id: Scalars['ID'];
  labels?: Maybe<Array<LabelType>>;
  mediaType?: Maybe<MediaTypes>;
  mediaUrl?: Maybe<Scalars['String']>;
  platform?: Maybe<SocialPlatformEnum>;
  postType?: Maybe<IgMediaPostType>;
  postedAt?: Maybe<Scalars['DateTime']>;
  programMedias?: Maybe<Array<ProgramMediaType>>;
};

export type VisibleMediaChild = {
  __typename: 'VisibleMediaChild';
  id: Scalars['ID'];
  mediaType?: Maybe<MediaTypes>;
  mediaUrl?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type WebhookIntegration = Integration & Node & {
  __typename: 'WebhookIntegration';
  apiUrl: Scalars['String'];
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

/** Yotpo Loyalty API Integration */
export type YotpoIntegration = Integration & Node & {
  __typename: 'YotpoIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  guid: Scalars['String'];
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type YotpoVizIntegration = Integration & Node & {
  __typename: 'YotpoVizIntegration';
  appKey: Scalars['String'];
  appSecret: Scalars['String'];
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  name: Scalars['String'];
};

export type ZaiusIntegration = Integration & Node & {
  __typename: 'ZaiusIntegration';
  capabilities: Array<IntegrationCapabilityEnum>;
  deliveryMethods: Array<IntegrationDeliveryMethodEnum>;
  id: Scalars['ID'];
  integrationType: IntegrationType;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type FbUserRefreshMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type FbUserRefreshMutation = { __typename: 'Mutations', refreshFbUser?: { __typename: 'RefreshFBUser', ok: boolean } | null };

export type HostCustomFieldFragment = { __typename: 'CustomFieldType', id: string, options?: Array<string> | null, position?: number | null, required: boolean, title: string, type: CustomFieldEnumType };

export type HostQueryVariables = Exact<{
  lcId: Scalars['ID'];
}>;


export type HostQuery = { __typename: 'Query', programHost?: { __typename: 'PublicProgramHost', allowFbAuth: boolean, igRequired: boolean, lcId: string, logoUrl?: string | null, socialPlatform?: SocialPlatformEnum | null, socialPlatforms?: Array<SocialPlatformEnum> | null, ttRequired: boolean, username?: string | null, customFields?: Array<{ __typename: 'CustomFieldType', id: string, options?: Array<string> | null, position?: number | null, required: boolean, title: string, type: CustomFieldEnumType }> | null, program?: { __typename: 'PublicProgram', id: string, autoApproved: boolean, name: string, programType?: ProgramTypeEnum | null } | null } | null };

export type RegisterForCampaignMutationVariables = Exact<{
  data: RegistrationInput;
  lcId: Scalars['String'];
}>;


export type RegisterForCampaignMutation = { __typename: 'Mutations', registerCampaignParticipant?: { __typename: 'RegisterCampaignParticipant', ok?: boolean | null, participants?: Array<{ __typename: 'RegisteredParticipant', email?: string | null, igUsername?: string | null, storefrontUrl?: string | null, ttUsername?: string | null }> | null } | null };

export type ValidateAndSaveFbUserMutationVariables = Exact<{
  fbToken: Scalars['String'];
}>;


export type ValidateAndSaveFbUserMutation = { __typename: 'Mutations', validateAndSaveFbUser?: { __typename: 'ValidateAndSaveFbUser', ok?: boolean | null, email?: string | null, usernames?: Array<string> | null } | null };

export const HostCustomFieldFragmentDoc = gql`
    fragment HostCustomField on CustomFieldType {
  id
  options
  position
  required
  title
  type
}
    `;
export const FbUserRefreshDocument = gql`
    mutation FbUserRefresh($token: String!) {
  refreshFbUser(fbToken: $token) {
    ok
  }
}
    `;
export const HostDocument = gql`
    query Host($lcId: ID!) {
  programHost(lcId: $lcId) {
    allowFbAuth
    customFields {
      ...HostCustomField
    }
    igRequired
    lcId
    logoUrl
    program {
      id
      autoApproved
      name
      programType
    }
    socialPlatform
    socialPlatforms
    ttRequired
    username
  }
}
    ${HostCustomFieldFragmentDoc}`;
export const RegisterForCampaignDocument = gql`
    mutation RegisterForCampaign($data: RegistrationInput!, $lcId: String!) {
  registerCampaignParticipant(data: $data, lcId: $lcId) {
    ok
    participants {
      email
      igUsername
      storefrontUrl
      ttUsername
    }
  }
}
    `;
export const ValidateAndSaveFbUserDocument = gql`
    mutation ValidateAndSaveFbUser($fbToken: String!) {
  validateAndSaveFbUser(fbToken: $fbToken) {
    ok
    email
    usernames
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    FbUserRefresh(variables: FbUserRefreshMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<FbUserRefreshMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<FbUserRefreshMutation>(FbUserRefreshDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FbUserRefresh', 'mutation');
    },
    Host(variables: HostQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HostQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HostQuery>(HostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Host', 'query');
    },
    RegisterForCampaign(variables: RegisterForCampaignMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterForCampaignMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RegisterForCampaignMutation>(RegisterForCampaignDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegisterForCampaign', 'mutation');
    },
    ValidateAndSaveFbUser(variables: ValidateAndSaveFbUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ValidateAndSaveFbUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ValidateAndSaveFbUserMutation>(ValidateAndSaveFbUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ValidateAndSaveFbUser', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;